import { createSelector, Selector } from "reselect";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  ILpReportsError,
  ILpReportsLoading,
  ILpReportsState
} from "@timhortons/common/src/redux/lp/reducers/reportsReducer";

import {
  LpAcknowledgementModel,
  LpReportsDataModal,
  LpReportTableResponseModel,
  LpStoreAccessSummaryDataModel,
  LpIndividualAccessSummaryDataModel,
  AllAreaManagersListDataModel,
  KPIDataModel,
  LpAuditChecklistDataModel
} from "@timhortons/common/src/models/lp/reports";
import { SelectedMonthFilter } from "@timhortons/common/src/modules/lp/components/dashboard";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  CountryDataListModel
} from "@timhortons/common/src/models/profile";

const lpReportsSelect = (state: IState): ILpReportsState => state.lp.reports;

const lpReportsLoading: Selector<IState, ILpReportsLoading> = createSelector(
  lpReportsSelect,
  (data) => data.loading
);

const lpReportsError: Selector<IState, ILpReportsError> = createSelector(
  lpReportsSelect,
  (data) => data.error
);

const lpReportsData: Selector<IState, LpReportsDataModal> = createSelector(
  lpReportsSelect,
  (data) => data.lpReportsData
);
const lpAcknowledgementData: Selector<IState, LpAcknowledgementModel> = createSelector(
  lpReportsSelect,
  (data) => data.lpAcknowledgementData
);
const postLpAckData: Selector<IState, any> = createSelector(
  lpReportsSelect,
  (data) => data.postLpAcknowledgement
);
const lpReportTableData: Selector<IState, LpReportTableResponseModel> = createSelector(
  lpReportsSelect,
  (data) => data.lpReportTableData
);
const getReportAuditId: Selector<IState, { auditId: string; reportId: number | null }> =
  createSelector(lpReportsSelect, (data) => data.reportGenerated);

const getLpSelectedMonthFilter: Selector<IState, { fromDate: Date; toDate: Date }> = createSelector(
  lpReportsSelect,
  (data) => data.lpMonthFilter
);
const getLpSelectedCountryFilter: Selector<IState, string> = createSelector(
  lpReportsSelect,
  (data) => data.lpCountryFilter
);

const fetchLpStoreAccessSummaryData: Selector<IState, LpStoreAccessSummaryDataModel> =
  createSelector(lpReportsSelect, (data) => data.lpStoreAccessSummaryData);

const getLpIndividualAccessSummaryData: Selector<IState, LpIndividualAccessSummaryDataModel> =
  createSelector(lpReportsSelect, (data) => data.lpIndividualAccessSummaryData);

const getLpAuditChecklistAccessData: Selector<IState, LpAuditChecklistDataModel> = createSelector(
  lpReportsSelect,
  (data) => data.lpAuditChecklistData
);

const getCountryTotalAuditDetailsData: Selector<IState, any> = createSelector(
  lpReportsSelect,
  (data) => data.countryTotalAuditsData
);
const getAreaManagerTotalAuditDetailsData: Selector<IState, any> = createSelector(
  lpReportsSelect,
  (data) => data.areaManagerTotalAuidtsData
);
const getLpAuditorTotalAuditDetailsData: Selector<IState, any> = createSelector(
  lpReportsSelect,
  (data) => data.lpAuditorTotalAuditsData
);

const fetchLpStoreAccessOpportunityData: Selector<IState, LpStoreAccessSummaryDataModel[]> =
  createSelector(lpReportsSelect, (data) => data.lpStoreAccessOpportunityData);

const generatedreportBuilderReponse: Selector<IState, any> = createSelector(
  lpReportsSelect,
  (data) => data.generatedReportBuilderResponse
);

const generatedGradingreportBuilderReponse: Selector<IState, any> = createSelector(
  lpReportsSelect,
  (data) => data.gradingTableData
);
const generateAreaOfImprovementReponse: Selector<IState, any> = createSelector(
  lpReportsSelect,
  (data) => data.areaOfImprovementBuilder
);
const brandStandardTableData: Selector<IState, any> = createSelector(
  lpReportsSelect,
  (data) => data.brandStandardTableData
);

export const LpReportsSelectors = {
  lpReportsSelect,
  lpReportsData,
  lpAcknowledgementData,
  getAreaManagerTotalAuditDetailsData,
  getLpAuditorTotalAuditDetailsData,
  lpReportsLoading,
  lpReportsError,
  lpReportTableData,
  postLpAckData,
  getReportAuditId,
  getLpSelectedMonthFilter,
  getLpSelectedCountryFilter,
  fetchLpStoreAccessSummaryData,
  getCountryTotalAuditDetailsData,
  getLpIndividualAccessSummaryData,
  fetchLpStoreAccessOpportunityData,
  generatedreportBuilderReponse,
  generatedGradingreportBuilderReponse,
  generateAreaOfImprovementReponse,
  brandStandardTableData,
  getLpAuditChecklistAccessData
};
