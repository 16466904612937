import { dateFormat } from "@timhortons/common/src/utils/dateFormat";
import {
  LpCategoriesNameModel,
  LpReportsDataModal,
  LpStoreAccessSummaryDataModel,
  LpIndividualAccessSummaryDataModel,
  LpReportsSummaryOpportunityDataModel,
  LpStoreRankingsTableData,
  LpIndividualCriticalCategoriesModel,
  LpKPIDataModel,
  LPReportBuilderResponseModel,
  LPGradingReportBuilderResponseModel,
  LpStoreRankingDataModel
} from "@timhortons/common/src/models/lp/reports";

const deleteLpDuplicateElements = (arr1: any[], arr2: any[]) => {
  let lastData: any[] = arr2;
  let ids = new Set(arr1.map(({ storeCode }) => storeCode));
  lastData = lastData.filter(({ storeCode }) => !ids.has(storeCode));
  return lastData;
};

export const formatterReports = async (
  opportunitApiData: any,
  reportsMasterData: any
): Promise<LpReportsDataModal> => {
  let rejectedData: LpCategoriesNameModel[];
  rejectedData = opportunitApiData.map((el: any) => {
    let obj: LpCategoriesNameModel;
    obj = {
      subCategoryName: null,
      subCategoryLabel: null,
      questions: []
    };
    obj.subCategoryLabel = el?.subCategoryLabel;
    obj.subCategoryName = el?.subCategoryName;

    el.questions.map((eachQuestion: any) => obj.questions.push(eachQuestion));
    return obj;
  });

  let slicedData = reportsMasterData;
  let finalData: LpReportsDataModal = {
    externalPDFlink: slicedData.signedUrl,
    isSigned: slicedData.isSigned,
    auditType: slicedData.lpAuditDetails.auditType,
    location: slicedData.lpAuditDetails.storeName,
    Details: {
      store: slicedData.lpAuditDetails.storeCode.toString(),
      auditor: slicedData.lpAuditDetails.auditorName,
      auditStartedDate: dateFormat(slicedData.lpAuditDetails.startTime),
      auditEndedDate: dateFormat(slicedData.lpAuditDetails.endTime),
      endTime: slicedData.lpAuditDetails.endTime + "Z",
      startTime: slicedData.lpAuditDetails.startTime + "Z",
      signedBy: slicedData.signedBy
    },
    resultStatus: slicedData.status,
    resultPercentage:
      slicedData.overallResult[Object.keys(slicedData.overallResult)[0]] === "NaN" ||
      Object.keys(reportsMasterData.overallResult).length === 0
        ? "0"
        : slicedData.overallResult[Object.keys(slicedData.overallResult)[0]].toFixed(2),
    grade: Object.keys(reportsMasterData.overallResult)[0],
    CategoryTableData: {
      CategoryList: reportsMasterData.categoryStandardReport,
      totalAvailableValue: "0",
      totalEarnedValue: "0",
      overAllPercentage: "0"
    },
    FaildCategoryTableData: [],
    OpportunityDetails: { rejectedData }
  };
  let totalAvailableValue = 0;
  let totalEarnedValue = 0;
  finalData.CategoryTableData.CategoryList = reportsMasterData.categoryStandardReport.map(
    (el: any, index: number) => {
      let obj: any;
      obj = {
        id: (index + 1).toString(),
        title: el.lpCategory,
        earnedValue: el.earnedPoints,
        availableValue: el.availablePoints,
        overallValue: el.overallPoints,
        status: el.status
      };
      totalAvailableValue += el.availablePoints;
      totalEarnedValue += el.earnedPoints;

      return obj;
    }
  );
  finalData.CategoryTableData.totalAvailableValue = totalAvailableValue.toString();
  finalData.CategoryTableData.totalEarnedValue = totalEarnedValue.toString();
  finalData.CategoryTableData.overAllPercentage = slicedData.lpCategoryScore.toString();
  finalData.FaildCategoryTableData = reportsMasterData.failedCategories.map(
    (el: any, index: number) => {
      let obj: any;
      obj = {
        id: (index + 1).toString(),
        title: el
      };
      return obj;
    }
  );
  return finalData;
};

export const lpStoreAccessSummaryFormatter = async (
  response: any
): Promise<LpStoreAccessSummaryDataModel> => {
  let data: LpStoreAccessSummaryDataModel;
  let kpiAvgScoreCalcLength = response?.brandStandardAverageReport.filter(
    (x: any) => x.averageScore != 0
  ).length;

  data = {
    actionPlansData: {
      series: [0, 0, 0],
      labels: ["Completed Action", "Pending Action", "Overdue Action"],
      totalReports: response && response.actionPlanReport.totalCount,
      inProcessReport: response && response.actionItemInProcessReport.inProcessCount
    },
    completeAverageScore: {
      name: [],
      percentage: []
    },
    monthOnMonthAverageData: {
      percentage: [],
      month: []
    },
    criticalPointsReport: {
      averageDeductedPoints: [],
      labels: [],
      shortLabel: []
    },
    brandStandardAverageReport: {
      series: [],
      categories: [],
      averageScore:
        kpiAvgScoreCalcLength > 0
          ? Number(
              (
                response &&
                response.brandStandardAverageReport.reduce(
                  (total: any, next: any) => total + next.averageScore,
                  0
                ) / kpiAvgScoreCalcLength
              ).toFixed(2)
            )
          : 0
    }
  };

  response &&
    response.actionPlanReport.report.map((el: any) => {
      if (el.label === "COMPLETED") {
        data.actionPlansData.series[0] = el.count;
      } else if (el.label === "NOT_STARTED") {
        data.actionPlansData.series[1] = el.count;
      } else if (el.label === "OVERDUE") {
        data.actionPlansData.series[2] = el.count;
      }
    });

  response &&
    response.storeReport.map((el: any) => {
      return {
        name: data.completeAverageScore.name.push(el.name),
        percentage: data.completeAverageScore.percentage.push(
          Number(el.averagePercentage.toFixed(2))
        )
      };
    });

  response &&
    response.monthOnMonthReport.map((el: any) => {
      return {
        percentage: data.monthOnMonthAverageData.percentage.push(el.averageScore),
        month: data.monthOnMonthAverageData.month.push(el.shortLabel)
      };
    });

  response &&
    response.criticalPointsReport.map((el: any) => {
      return {
        averageDeductedPoints: data.criticalPointsReport.averageDeductedPoints.push(
          el.averageDeductedPoints
        ),
        labels: data.criticalPointsReport.labels.push(el.label),
        shortLabel: data.criticalPointsReport.shortLabel.push(el.shortLabel)
      };
    });

  response &&
    response.brandStandardAverageReport.map((el: any) => {
      return {
        series: data.brandStandardAverageReport.series.push(Number(el.averageScore.toFixed(0))),
        categories: data.brandStandardAverageReport.categories.push(el.name)
      };
    });

  return data;
};

export const lpIndividualAccessSummaryFormatter = async (
  response: any
): Promise<LpIndividualAccessSummaryDataModel> => {
  let data: any;
  console.log(response, "fdadsfadfsaf");

  data = {
    actionPlansData: {
      series: [0, 0],
      labels: ["Audits Passed", "Audits Failed"],
      totalReports: 0
    },
    auditsConducted: [],
    comparisonReport: [],
    averageScore: {
      name: [],
      percentage: []
    },
    criticalPointsReport: {
      averageDeductedPoints: [],
      labels: [],
      shortLabel: []
    },
    gradeDistribution: {
      label: [],
      storeCount: []
    },
    monthOnMonthAverageData: {
      month: [],
      percentage: []
    },
    opportunityData: [],
    lpKPIData: [],
    brandStandardsCountryWise: [],
    criticalCategories: [],
    auditCountTable: [],
    failedReports: [],
    areaManagerRankingsTableData: [] // TODO: write formatter for area manager ranking
  };

  data.actionPlansData.series[0] =
    response && response.auditPerformanceReport && response.auditPerformanceReport.passed;
  data.actionPlansData.series[1] =
    response && response.auditPerformanceReport && response.auditPerformanceReport.failed;
  data.actionPlansData.totalReports =
    response && response.auditPerformanceReport && response.auditPerformanceReport.totalAudits;

  response &&
    response?.auditsConductedReportList?.map((el: any, i: any) => {
      let object: any = {
        id: i,
        countryName: el.countryName,
        failed: el.failed,
        passed: el.passed,
        totalAudits: el.totalAudits
      };

      data.auditsConducted[i] = object;
    });

  response &&
    response?.auditCountReport?.map((el: any, i: any) => {
      let object: any = {
        id: i,
        auditorName: el.auditorName,
        monthlyCount: el.monthlyCount,
        total: el.total
      };

      data.auditCountTable[i] = object;
    });

  response &&
    response?.brandStandardFailedReport?.map((el: any, i: any) => {
      let object: any = {
        id: i,
        brandStandard: el.categoryName,
        restaurantCount: el.restaurantCount,
        failedRestaurants: el.failedRestaurants
      };
      data.failedReports[i] = object;
    });

  response &&
    response.averageScoreReport &&
    response.averageScoreReport.map((el: any) => {
      return {
        name: data.averageScore.name.push(el.shortLabel),
        percentage: data.averageScore.percentage.push(el.overallResult)
      };
    });

  response &&
    response.brandStandardOverallReport &&
    response.brandStandardOverallReport.map((el: any) => {
      return {
        label: data.gradeDistribution.label.push(el.categoryName),
        storeCount: data.gradeDistribution.storeCount.push(Number(el.percentage.toFixed(2)))
      };
    });

  response &&
    response.monthOnMonthReport &&
    response.monthOnMonthReport.map((el: any) => {
      return {
        month: data.monthOnMonthAverageData.month.push(el.shortLabel),
        percentage: data.monthOnMonthAverageData.percentage.push(el.averageScore)
      };
    });

  response &&
    response?.opportunityStoreFrequency?.map((el: any, i: any) => {
      let object: LpReportsSummaryOpportunityDataModel = {
        categoryName: el.categoryName,
        points: el.maxPoints,
        question: el.subCategoryName,
        questionLabel: el.label,
        frequency: el.storeFrequency
      };

      data.opportunityData[i] = object;
    });

  response &&
    response?.brandStandardOverallReport?.map((el: any, i: any) => {
      let object: any = {
        id: i,
        categoryName: el.categoryName,
        countryWiseBreakdown: el.countryWiseBreakdown
      };
      data.brandStandardsCountryWise[i] = object;
    });

  response &&
    response?.comparisonReport?.map((el: any, i: any) => {
      let object: any = {
        id: i,
        categoryName: el.categoryName,
        results: el.results
      };
      data.comparisonReport[i] = object;
    });

  response &&
    response?.criticalPointsReport?.map((el: any) => {
      return {
        averageDeductedPoints: data.criticalPointsReport.averageDeductedPoints.push(
          el.averageDeductedPoints
        ),
        labels: data.criticalPointsReport.labels.push(el.label),
        shortLabel: data.criticalPointsReport.shortLabel.push(el.shortLabel)
      };
    });

  response &&
    response?.nonCriticalCategories?.map((el: any, i: number) => {
      let obj: LpKPIDataModel = {
        id: i,
        label: el.label,
        tillsScore: el.tillsScore,
        pettyCashScore: el.pettyCashScore,
        salesReportsScore: el.salesReportsScore,
        healthSafetyScore: el.healthSafetyScore,
        inventoryScore: el.inventoryScore,
        assetManagementScore: el.assetManagementScore,
        voidsDiscountsScore: el.voidsDiscountsScore,
        wastageScore: el.wastageScore,
        municipalityRequirementsScore: el.municipalityRequirementsScore,
        overallScore: el.averageScore
      };
      data.lpKPIData[i] = obj;
    });

  response?.brandStandardOverallReport?.map((el: any, i: number) => {
    let obj: any = {
      id: i,
      brandStandards: el.categoryName,
      percentage: Number(el.percentage.toFixed(2)),
      earned: el.earned,
      totalPoints: el.available
    };
    data.criticalCategories[i] = obj;
  });

  return data;
};
export const lpAuditChecklistFormatter = async (
  response: any
): Promise<LpIndividualAccessSummaryDataModel> => {
  let data: any;
  data = {
    auditChecklistData: [],
    totalElements: 0
  };

  data.totalElements = response.totalElements;
  data.auditChecklistData = response.content;

  return data;
};
export const lpIndividualAccessSummarySRTableFormatter = async (
  response: any
): Promise<LpStoreRankingDataModel> => {
  let data: LpStoreRankingDataModel = {
    totalElements: response?.totalElements,
    content: []
  };
  response &&
    response?.content?.map((el: any, index: number) => {
      let obj: LpStoreRankingsTableData = {
        rank: el.rank,
        avgScore: el.avgScore,
        grade: el.grade?.toUpperCase(),
        country: el.countryName,
        storeName: el.storeName,
        criticalPoints: el.criticalPoints,
        overallScore: el.avgScore
      };
      data.content[index] = obj;
    });
  return data;
};
export const reportBuilderFormatter = async (
  metaData: any,
  response: any
): Promise<LPReportBuilderResponseModel> => {
  let levelOfReport: string = "";
  let reportType: string = "";
  if (metaData.object.levelOfReport === "COUNTRY") {
    levelOfReport = "Country";
  } else if (metaData.object.levelOfReport === "COUNTRY_MANAGER") {
    levelOfReport = "Country Manager";
  } else if (metaData.object.levelOfReport === "AREA_MANAGER") {
    levelOfReport = "Area Manager";
  } else if (metaData.object.levelOfReport === "CHANNEL") {
    levelOfReport = "Channels";
  } else if (metaData.object.levelOfReport === "OPS_MANAGER") {
    levelOfReport = "Operational Manager";
  } else if (metaData.object.levelOfReport === "CLUSTER_HEAD_OF_OPS") {
    levelOfReport = "Cluster Head of Ops";
  } else if (metaData.object.levelOfReport === "LP_AUDITOR") {
    levelOfReport = "LP Auditor";
  } else {
    levelOfReport = "Stores";
  }
  if (metaData.reportType === "DETAILED") {
    reportType = "Detailed";
  } else if (metaData.reportType === "COMPARISON") {
    reportType = "Comparison";
  } else {
    reportType = "Highest to Lowest";
  }
  let data: LPReportBuilderResponseModel = {
    metaData: {
      startDate: metaData.object.startDate,
      endDate: metaData.object.endDate,
      reportType: reportType,
      levelOfReport: levelOfReport,
      countryLabel: response?.content?.length > 0 ? response?.content[0]?.countryLabel : null,
      // Note:Didn't changed the model as
      reportEnum: metaData.object.levelOfReport,
      selectedDateRange: metaData.selectedDateRange,
      quaterLabel: metaData.quaterLabel
    },
    apiResponse: {
      content: [],
      totalElements: response.totalElements
    }
  };
  response &&
    response.content.map((item: any, index: number) => {
      let obj = {
        id: item.code ? item.code : item.employeeId,
        label: item.label,
        grade: item.grade,
        overallScore: item.overallScore,
        averageScore: item.averageScore,
        municipalityRequirementsScore: item.municipalityRequirementsScore,
        assetManagementScore: item.assetManagementScore,
        inventoryScore: item.inventoryScore,
        healthSafetyScore: item.healthSafetyScore,
        pettyCashScore: item.pettyCashScore,
        salesReportsScore: item.salesReportsScore,
        tillsScore: item.tillsScore,
        voidsDiscountsScore: item.voidsDiscountsScore,
        wastageScore: item.wastageScore,
        cashHandlingScore: item.cashHandlingScore,
        safeVaultScore: item.safeVaultScore,
        bankingScore: item.bankingScore,
        expiryScore: item.expiryScore
      };
      data.apiResponse.content[index] = obj;
    });
  return data;
};

export const gradingReportBuilderFormatter = async (
  metaData: any,
  response: any
): Promise<LPGradingReportBuilderResponseModel> => {
  let levelOfReport: string = "";
  let reportType: string = "";
  if (metaData.object.levelOfReport === "COUNTRY") {
    levelOfReport = "Country";
  } else if (metaData.object.levelOfReport === "COUNTRY_MANAGER") {
    levelOfReport = "Country Manager";
  } else if (metaData.object.levelOfReport === "AREA_MANAGER") {
    levelOfReport = "Area Manager";
  } else if (metaData.object.levelOfReport === "CHANNEL") {
    levelOfReport = "Channels";
  } else if (metaData.object.levelOfReport === "OPS_MANAGER") {
    levelOfReport = "Operational Manager";
  } else if (metaData.object.levelOfReport === "CLUSTER_HEAD_OF_OPS") {
    levelOfReport = "Cluster Head of Ops";
  } else if (metaData.object.levelOfReport === "LP_AUDITOR") {
    levelOfReport = "LP Auditor";
  } else {
    levelOfReport = "Stores";
  }
  if (metaData.reportType === "DETAILED") {
    reportType = "Detailed";
  } else if (metaData.reportType === "COMPARISON") {
    reportType = "Comparison";
  } else {
    reportType = "Highest to Lowest";
  }
  let data: LPGradingReportBuilderResponseModel = {
    metaData: {
      startDate: metaData.object.startDate,
      endDate: metaData.object.endDate,
      reportType: reportType,
      levelOfReport: levelOfReport,
      countryLabel: response?.content?.length > 0 ? response?.content[0]?.countryLabel : null
    },
    apiResponse: {
      content: [],
      totalElements: response.totalElements
    }
  };

  response &&
    response.content.map((item: any, index: number) => {
      let obj = {
        id: item.code ? item.code : item.employeeId,
        label: item.label,
        percentage: item.percentage,
        totalStores: item.totalStores,
        presentGrade: item.presentGrade,
        previousGrade: item.previousGrade,
        a: item.a,
        b: item.b,
        c: item.c,
        f: item.f
      };
      data.apiResponse.content[index] = obj;
    });
  return data;
};
