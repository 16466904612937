import React, { ReactElement, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LpReportsActions } from "@timhortons/common/src/redux/lp/actions/reportsAction";
import { LpReportsSelectors } from "@timhortons/common/src/redux/lp/selectors/reportsSelector";
import ReportsTableHeader from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/reportsTableHeader";
import ReportAuditTable from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/reportAuditTable";
import { IState } from "@timhortons/common/src/store/interfaces";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import {
  ILpReportsLoading,
  ILpReportsError
} from "@timhortons/common/src/redux/lp/reducers/reportsReducer";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import LpReportsSummary from "@timhortons/common/src/modules/lp/components/dashboard/reports/summary";
import { Roles } from "@timhortons/common/src/constants/roles";
import { ReportsBuilderActions } from "@timhortons/common/src/redux/reportBuilder/actions/reportsAction";
import { reportsBuilderSelectors } from "@timhortons/common/src/redux/reportBuilder/selectors/reportsSelector";
import { CountryDataListModel } from "@timhortons/common/src/models/profile";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import AuditChecklist from "@timhortons/common/src/modules/lp/components/dashboard/reports/auditChecklist";

interface IProps {
  history: any;
  translate?: any;
  user?: IUserDetails;
  getLpSelectedMonthFilter: Function;
  getLpSelectedCountryFilter: Function;
  getLpReportTableData: Function;
  getAllCountriesLoading: Function;
  reportTableData: any;
  loading: ILpReportsLoading;
  error: ILpReportsError;
  gccCountryData: CountryDataListModel;
}

export interface IReportTableData {
  userId: string;
  storeId: number;
  pageNumber: number;
  sortOrder: string;
  pageSize: number;
  visitType: string;
  fromDate: Date;
  toDate: Date;
  search: string;
  isIndividualLogin: boolean;
  columnName: string;
}

export enum ReportTable {
  audits = "Audits",
  summary = "Summary",
  reportBuilder = "Report Builder",
  auditChecklistOverview = "Audit Checklist Overview"
}

function Reports(props: IProps): ReactElement {
  const {
    translate,
    user: currentUserRoles,
    history,
    getLpReportTableData,
    getLpSelectedMonthFilter,
    getLpSelectedCountryFilter,
    reportTableData,
    loading,
    error,
    getAllCountriesLoading,
    gccCountryData
  } = props;

  const reportApiData: IReportTableData = {
    userId: currentUserRoles && currentUserRoles.empId,
    storeId: currentUserRoles && currentUserRoles.storeCode,
    pageNumber: 1,
    sortOrder: "desc",
    pageSize: 10,
    fromDate: null,
    toDate: null,
    search: "",
    visitType: "",
    isIndividualLogin: false,
    columnName: ""
  };
  const [finalReportData, setReportData] = useState(reportApiData);
  const [currentTable, setCurrentTable] = useState<ReportTable>(
    currentUserRoles?.roles?.includes(Roles.AreaTrainer) ? ReportTable.summary : ReportTable.audits
  );
  const [isIndividualLogin, setIndividualLogin] = useState(false);

  const handleRedirection = (auditId: number, reportId: number): void => {
    let searchParams = { auditId: auditId, reportId: reportId };
    navigation(history, Pathnames.detailedReport, searchParams, currentUserRoles);
  };
  const isReportBuilderAllowed: boolean = useMemo(() => {
    const allowedRoleArr = [
      Roles.SuperAdmin,
      Roles.TrainingAndDevLead,
      Roles.TrainingAndDevManager,
      Roles.HeadLP,
      Roles.AuditorLP
    ];
    if (allowedRoleArr.includes(currentUserRoles?.roles[0])) return true;
    return false;
  }, []);

  return (
    <View style={styles.container}>
      <ReportsTableHeader
        currentTable={currentTable}
        setCurrentTable={setCurrentTable}
        translate={translate}
        isIndividualLogin={isIndividualLogin}
        setReportData={setReportData}
        getReportTableData={getLpReportTableData}
        finalReportData={finalReportData}
        getLpSelectedMonthFilter={getLpSelectedMonthFilter}
        getLpSelectedCountryFilter={getLpSelectedCountryFilter}
        isReportBuilderAllowed={isReportBuilderAllowed}
        user={currentUserRoles}
        getAllCountriesLoading={getAllCountriesLoading}
        gccCountryData={gccCountryData}
      />
      <View style={styles.tableContainer}>
        {
          (currentTable === ReportTable.audits && (
            <ReportAuditTable
              setReportData={setReportData}
              getLpReportTableData={getLpReportTableData}
              setCurrentTable={setCurrentTable}
              setIndividualLogin={setIndividualLogin}
              history={history}
              finalReportData={finalReportData}
              translate={translate}
              reportTableData={reportTableData}
              currentUserRoles={currentUserRoles}
              loading={loading.lpReportTableData}
              error={error.lpReportTableData}
              handleViewIcon={handleRedirection}
            />
          )) ||
            (currentTable === ReportTable.summary && (
              <>
                <LpReportsSummary
                  translate={translate}
                  user={currentUserRoles}
                  gccCountryData={gccCountryData}
                />
              </>
            )) ||
            (currentTable === ReportTable.auditChecklistOverview && (
              <>
                <AuditChecklist
                  translate={translate}
                  user={currentUserRoles}
                  gccCountryData={gccCountryData}
                />
              </>
            ))
          //  || (
          //   <ReportBuilder
          //     user={currentUserRoles}
          //     translate={translate}
          //     history={history}
          //     isLp={true}
          //     />
          //     )
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 30,
    flexDirection: "column"
  },
  tableContainer: {
    flex: 1,
    marginTop: 16,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey19,
    borderWidth: 1
  }
});

const mapStateToProps = (state: IState) => {
  return {
    loading: LpReportsSelectors.lpReportsLoading(state),
    error: LpReportsSelectors.lpReportsError(state),
    reportTableData: LpReportsSelectors.lpReportTableData(state),
    gccCountryData: reportsBuilderSelectors.getAllCountries(state)
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...LpReportsActions, ...ReportsBuilderActions }, dispatch) };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
