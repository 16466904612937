import React from "react";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, TextSize, Text } from "@timhortons/common/src/components/atoms/text";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { LpIndividualCriticalCategoriesModel } from "@timhortons/common/src/models/lp/reports";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import CommonPagination from "@timhortons/common/src/modules/rev/organisms/commonPagination";

interface IProps {
  loading: boolean;
  translate: any;
  headerTitle: string;
  criticalCategoriesData: LpIndividualCriticalCategoriesModel[];
  handlePagination?: Function;
  containerStyle?: StyleProp<ViewStyle>;
}

export interface IHeaderData {
  id: string;
  name: string;
  cellStyle?: StyleProp<ViewStyle>;
  clickable?: boolean;
}

export default function BrandStandardTableUi(props: IProps) {
  const {
    translate,
    criticalCategoriesData,
    headerTitle = translate("By Stores"),
    loading = false,
    handlePagination,
    containerStyle
  } = props;

  const headerData: IHeaderData[] = [
    { id: "1", name: "S.No" },
    { id: "2", name: translate("Brand Standards") },
    { id: "3", name: translate("Percentage") },
    { id: "4", name: translate("Earned") },
    { id: "5", name: translate("Total Points") }
  ];
  const getDividerUI = (): JSX.Element => {
    return <Divider orientation={Orientations.Vertical} dividerStyle={styles.dashedDivider} />;
  };

  const getCellUI = (value: number, header: boolean) => {
    return (
      <Cell title={value === null ? "-" : value + "%"} cellStyle={header && styles.headerColor} />
    );
  };
  const getTableRowUI = (item: any, index: any, header: boolean): JSX.Element => {
    return (
      <Row rowStyle={styles.flexStyle}>
        <Cell
          title={index + 1}
          cellStyle={[
            styles.contentCellHeader,
            header && styles.headerColor,
            { justifyContent: "center" }
          ]}
        />
        <Cell
          title={item.brandStandards}
          cellStyle={[
            styles.contentCell,
            header && styles.headerColor,
            { justifyContent: "flex-start", paddingLeft: 18 }
          ]}
        />
        {getCellUI(item.percentage, header)}
        <Cell title={item.earned} cellStyle={[styles.contentCell, header && styles.headerColor]} />
        <Cell
          title={item.totalPoints}
          cellStyle={[styles.contentCell, header && styles.headerColor]}
        />
      </Row>
    );
  };

  const getHeaderUI = (header: IHeaderData[]) => {
    return header
      .filter((i) => i !== undefined)
      .map((item: IHeaderData, index: number) => (
        <React.Fragment key={index}>
          {index === 5 && getDividerUI()}
          <HeaderCell
            title={item && item.name}
            cellStyle={[
              index === 0 && styles.contentCellHeader,
              index === 1 && { paddingLeft: 18 }
            ]}
            wrapperStyles={{
              justifyContent: index === 1 ? "flex-start" : "center",
              flex: 1
            }}
            titleStyle={styles.centerAlign}
          />
        </React.Fragment>
      ));
  };

  return (
    <View style={[styles.flexStyle, containerStyle]}>
      <Table>
        <Row header>{getHeaderUI(headerData)}</Row>
        {loading ? (
          <Loader type="component" />
        ) : criticalCategoriesData && criticalCategoriesData.length > 0 ? (
          <>
            <FlatList
              extractor={(item) => item.id.toString()}
              listData={criticalCategoriesData}
              seperator={() => <Divider dividerStyle={styles.solidDivider} />}
              listRenderer={(item: any, index) => getTableRowUI(item, index, false)}
            />
          </>
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
        )}
      </Table>
      {handlePagination && (
        <CommonPagination
          handlePagination={handlePagination}
          pageNum={1}
          totalRecords={criticalCategoriesData?.length}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  dashedDivider: {
    borderWidth: 1,
    borderRadius: 0.1,
    borderStyle: "dashed",
    borderColor: colorPallete.grey7
  },
  solidDivider: {
    borderColor: colorPallete.white1
  },
  contentCell: {
    justifyContent: "center"
  },
  contentCellHeader: {
    justifyContent: "center",
    minWidth: 100,
    flex: 0,
    paddingRight: 18,
    ...I18nService.select({
      rtl: { paddingEnd: 18 },
      ltr: { paddingStart: 18 }
    })
  },
  upperheaderColor: {
    backgroundColor: colorPallete.variant16
  },
  centerAlign: {
    textAlign: "center"
  },
  flexStyle: {
    flex: 1
  },
  headerColor: {
    backgroundColor: colorPallete.grey9
  },
  headerTextStyle: {
    padding: 24
  },
  textStyleChip: {
    borderRadius: 8,
    paddingVertical: 3,
    paddingHorizontal: 16,
    alignItems: "center"
    // backgroundColor: colorPallete.grey8
  }
});
