import React from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, TextSize, Text } from "@timhortons/common/src/components/atoms/text";
import { Cell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import Loader from "@timhortons/common/src/components/molecules/loader";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import { AuditCountTableProps } from "@timhortons/common/src/modules/lp/components/dashboard/reports/summary/template/lpReportSummaryTemplate";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface IProps {
  loading: boolean;
  translate: any;
  headerTitle: string;
  transformedAuditCountTable: AuditCountTableProps[];
}

export interface HeaderCellProps {
  style?: object;
  children: string;
}
export interface TotalType {
  Total: number;
  [key: string]: number;
}
const AuditCountTable = (props: IProps) => {
  const {
    translate,
    headerTitle = translate("By Stores"),
    loading = false,
    transformedAuditCountTable
  } = props;

  const HeaderCells = ({ style, children }: HeaderCellProps) => (
    <Text
      textSize={TextSize.Regular}
      fontWeight={FontWeight.Regular}
      textStyle={[styles.headercellCont, style]}
      testId="header cells"
    >
      {translate(children)}
    </Text>
  );

  const MonthHeaders = ({ months }: { months: string[] }) => (
    <View style={[styles.monthContainer]}>
      {months.map((month: any) => (
        <HeaderCells key={month}>{month}</HeaderCells>
      ))}
    </View>
  );
  const getDividerUI = (): JSX.Element => {
    return <Divider orientation={Orientations.Vertical} dividerStyle={styles.dashedDivider} />;
  };

  const totals: TotalType = months.reduce(
    (acc, month) => ({
      ...acc,
      [month]: transformedAuditCountTable.reduce((sum: any, row: any) => sum + row[month], 0)
    }),
    { Total: transformedAuditCountTable.reduce((sum: any, row: any) => sum + row.total, 0) }
  );
  return (
    <View style={[styles.flexStyle, styles.container]}>
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.SemiBold}
        textStyle={styles.heading}
        testId="table heading"
      >
        {translate("Audit Count Overview")}
      </Text>

      <ScrollView horizontal contentContainerStyle={{ flexGrow: 1 }}>
        <View style={{ width: "100%" }}>
          {/* Table Header */}
          <Table>
            <View style={styles.row}>
              <HeaderCells style={[styles.auditorCell, { padding: 12 }, styles.centerAlign]}>
                Audited By - Names
              </HeaderCells>
              {getDividerUI()}
              <View style={styles.clubbedHeader}>
                <HeaderCells
                  style={[styles.monthCell, styles.countryAuditBg, { width: "100%", padding: 12 }]}
                >
                  Count of Audits
                </HeaderCells>
                <MonthHeaders months={months} />
              </View>
              {getDividerUI()}
              <HeaderCells style={[styles.totalCell, { padding: 12 }]}>Total</HeaderCells>
            </View>

            {loading ? (
              <Loader type="component" />
            ) : transformedAuditCountTable.length > 0 ? (
              <>
                {/* Table Rows */}

                {transformedAuditCountTable.map((row: any) => (
                  <View key={row.id} style={styles.row}>
                    <Text
                      textSize={TextSize.Regular}
                      fontWeight={FontWeight.Regular}
                      textStyle={[styles.auditorCell, { padding: 18 }]}
                      testId="audit cells"
                    >
                      {translate(row.auditorName)}
                    </Text>

                    {getDividerUI()}
                    <View style={[styles.clubbedHeader, { flexDirection: "row" }]}>
                      {months.map((month) => (
                        <Text
                          textSize={TextSize.Regular}
                          fontWeight={FontWeight.Regular}
                          textStyle={[styles.monthCell, { padding: 18 }]}
                          testId="month cells"
                        >
                          {translate(row[month] || "-")}
                        </Text>
                      ))}
                    </View>
                    {getDividerUI()}

                    <Text
                      textSize={TextSize.Regular}
                      fontWeight={FontWeight.Regular}
                      textStyle={[styles.totalCell, { padding: 18 }]}
                      testId="total cells"
                    >
                      {translate(row.total)}
                    </Text>
                  </View>
                ))}

                {/* Totals Row */}
                <View style={[styles.row, styles.totalRow]}>
                  <Text
                    textSize={TextSize.Regular}
                    fontWeight={FontWeight.Regular}
                    textStyle={[styles.auditorCell, { padding: 18 }]}
                    testId="audits cells"
                  >
                    {translate("Total")}
                  </Text>
                  {getDividerUI()}
                  <View style={[styles.clubbedHeader, { flexDirection: "row" }]}>
                    {months.map((month) => (
                      <Text
                        textSize={TextSize.Regular}
                        fontWeight={FontWeight.Regular}
                        textStyle={[styles.monthCell, { padding: 18 }]}
                        testId="months cells"
                      >
                        {translate(totals[month])}
                      </Text>
                    ))}
                  </View>

                  {getDividerUI()}
                  <Text
                    textSize={TextSize.Regular}
                    fontWeight={FontWeight.Regular}
                    textStyle={[styles.totalCell, { padding: 18 }]}
                    testId="totals cells"
                  >
                    {translate(totals.Total)}
                  </Text>
                </View>
              </>
            ) : (
              <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
            )}
          </Table>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colorPallete.grey7,
    marginTop: 24
  },
  heading: {
    padding: 24
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd"
  },
  headercellCont: {
    textAlign: "center",
    width: "8.5%",
    padding: 12,
    backgroundColor: "#F2F0F0"
  },

  auditorCell: {
    width: "10%"
  },
  monthCell: {
    flex: 1,
    display: "flex",
    justifyContent: "center"
  },
  totalCell: {
    flex: 1,
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  totalRow: {
    backgroundColor: "#F2F0F0",
    fontWeight: "bold"
  },
  clubbedHeader: {
    flexDirection: "column",
    width: "80%",
    justifyContent: "center"
  },
  monthContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between"
  },
  flexStyle: {
    flex: 1
  },
  countryAuditBg: {
    backgroundColor: "#3366990D"
  },
  dashedDivider: {
    borderWidth: 1,
    borderRadius: 0.1,
    borderStyle: "dashed",
    borderColor: colorPallete.grey7
  },
  centerAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default AuditCountTable;
