import React, { useEffect } from "react";
import { StyleSheet } from "react-native";
import { fetchYMax, graphColors } from "@timhortons/common/src/utils/graphColorHelper";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { LpIndividualAccessSummaryDataModel } from "@timhortons/common/src/models/lp/reports";
import LpReportSummaryTemplate from "@timhortons/common/src/modules/lp/components/dashboard/reports/summary/template/lpReportSummaryTemplate";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { lpStoreRankingTableHeaderData } from "@timhortons/common/src/modules/lp/components/dashboard/reports/summary/momGraphHelper";
import { ILpReportsLoading } from "@timhortons/common/src/redux/lp/reducers/reportsReducer";
export interface MonthlyCategoryDataProp {
  id: number;
  categoryName: string;
  [month: string]: number | string | null;
}
interface ResultItem {
  item: string;
  value: number;
  startDate?: number;
  endDate?: number;
}

interface Category {
  id: number;
  categoryName: string;
  results: ResultItem[];
}

interface TransformedEntry {
  name: string;
  data: number[];
}

interface IProps {
  translate?: any;
  user: IUserDetails;
  lpSummaryData: any;
  loading: boolean;
  error: string;
  storeRakingTableLoading: boolean;
  totalAuditDetailsTablesLoading: ILpReportsLoading;
  storeRakingTableError: string;
  pageNumber: number;
  handlePageNo: Function;
  areaManagerTotalAuditData: any;
  countryTotalAuditData: any;
  lpAuditorTotalAuditData: any;
  handleCountryPage: Function;
  handleAreaManagerPage: Function;
  handleLpAuditorPage: Function;
  lpAuditorPageNo: number;
  areaManagerPageNo: number;
  countryPageNo: number;
  selectedCountry: string;
  selectedMonth?: { fromDate: Date; toDate: Date; monthly: any; quarterly: any };
}

export default function AdminSummary(props: IProps) {
  const {
    translate,
    user,
    lpSummaryData,
    loading,
    error,
    storeRakingTableLoading,
    storeRakingTableError,
    pageNumber,
    handlePageNo,
    areaManagerTotalAuditData,
    countryTotalAuditData,
    lpAuditorTotalAuditData,
    handleCountryPage,
    handleAreaManagerPage,
    handleLpAuditorPage,
    lpAuditorPageNo,
    areaManagerPageNo,
    countryPageNo,
    selectedCountry,
    totalAuditDetailsTablesLoading,
    selectedMonth
  } = props;

  const gradeBarChartData = {
    series: [
      {
        data: lpSummaryData && lpSummaryData?.gradeDistribution?.storeCount
      }
    ],
    columnWidth: "25",
    categories: lpSummaryData && lpSummaryData?.gradeDistribution?.label,
    categoryColors: graphColors.gradeGraph,
    xAxisTitle: "Restaurants Passed (Percentage %)",
    yAxisTitle: "Brand Standards",
    leftTitle: "Overall Brand Standards Compliance",
    rightTitle: "",
    height: 800,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    ymax: fetchYMax(lpSummaryData && lpSummaryData?.gradeDistribution?.storeCount, 25),
    isHorizontal: true,
    barHeight: "30",
    labelSuffix: "%",
    xAxisLabel: "%"
  };

  const transformedCountryWiseData = lpSummaryData?.auditsConducted
    .filter((country: any) => country.countryName !== "Total")
    .map((country: any) => {
      return {
        name: country.countryName,
        data: lpSummaryData?.brandStandardsCountryWise.map((category: any) => {
          const countryData = category.countryWiseBreakdown.find(
            (item: any) => item.countryName === country.countryName
          );
          return countryData && !isNaN(Number(countryData.percentage))
            ? countryData?.percentage.toFixed(2)
            : 0;
        })
      };
    });

  const transfomedCountryWiseTable = lpSummaryData?.brandStandardsCountryWise.map(
    (category: any, index: any) => {
      const transfomedData: { [key: string]: any } = {
        id: index,
        categoryName: category.categoryName
      };
      lpSummaryData?.auditsConducted.forEach((country: any) => {
        let entry = category?.countryWiseBreakdown.find(
          (d: any) => d.countryName === country.countryName
        );
        transfomedData[country.countryName] =
          entry && !isNaN(Number(entry.percentage)) ? entry.percentage.toFixed(2) : 0;
      });
      return transfomedData;
    }
  );

  const transformedAuditCountTable = lpSummaryData?.auditCountTable.map((val: any) => {
    const transformedData: {
      id: any;
      auditorName: any;
      total: any;
      [key: string]: any;
    } = {
      id: val.id,
      auditorName: val.auditorName,
      total: val.total
    };
    val.monthlyCount.map((month: any) => {
      transformedData[month.monthName] = month.auditCount;
    });
    return transformedData;
  });

  const transformedFailedRestaurant = lpSummaryData?.failedReports.map((val: any) => {
    const transformedData: Record<string, any[]> = {};

    const formattedRestaurants = val.failedRestaurants.map((restaurant: any, index: any) => ({
      id: index + 1,
      name: restaurant.restaurantName || "-",
      country: restaurant.country || "-",
      storeCode: restaurant.restaurantCode || "-",
      auditedBy: restaurant.auditedBy || "-",
      storeManager: restaurant.storeManager || "-"
    }));

    transformedData[val.brandStandard] = formattedRestaurants;

    return transformedData;
  });

  const transfomedMonthQuarterBar = lpSummaryData?.comparisonReport.reduce(
    (acc: TransformedEntry[], category: Category) => {
      category.results.forEach(({ item, value }) => {
        let existingEntry = acc.find((entry) => entry.name === item);

        if (existingEntry) {
          existingEntry.data.push(value);
        } else {
          acc.push({ name: item, data: [value] });
        }
      });

      return acc;
    },
    []
  );

  const transfomedMonthQuarterTable = lpSummaryData?.comparisonReport.map(
    (category: any, index: any) => {
      const transformedData: MonthlyCategoryDataProp = {
        id: index,
        categoryName: category.categoryName
      };

      category.results.forEach((result: any) => {
        transformedData[result.item] = result.value.toFixed(2);
      });

      return transformedData;
    }
  );

  const PerformanceBrandStandardData = {
    series: transformedCountryWiseData,
    columnWidth: "25",
    categories: lpSummaryData?.brandStandardsCountryWise?.map((val: any) => val.categoryName),
    categoryColors: graphColors.gradeGraph,
    xAxisTitle: "Restaurants Passed (Percentage %)",
    yAxisTitle: "Brand Standards",
    leftTitle: "Performance of Brand Standards",
    rightTitle: "",
    height: 800,
    barHeight: "30",
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "%",
    ymax: fetchYMax(lpSummaryData && lpSummaryData?.gradeDistribution?.storeCount, 25),
    isHorizontal: true,
    isStacked: true,
    xAxisLabel: "%",
    customColor: ["#C8102E", "#EBDDC1", "#735343", "#BF8751", "#C5AB9F", "#F9F4DE"]
  };

  const MonthlyPerformanceBrandStandardData = {
    series: transfomedMonthQuarterBar,
    columnWidth: "35",
    categories:
      lpSummaryData && lpSummaryData?.comparisonReport.map((val: any) => val.categoryName),
    categoryColors: graphColors.gradeGraph,
    // xAxisTitle: "Restaurants Passed (Percentage %)",
    // yAxisTitle: "Brand Standards",
    leftTitle: `Detailed Performance Comparison ${
      selectedMonth?.monthly ? `- Monthly` : selectedMonth?.quarterly ? `- Quarterly` : ""
    }`,
    rightTitle: "",
    height: 400,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "%",
    ymax: fetchYMax(lpSummaryData && lpSummaryData?.gradeDistribution?.storeCount, 25),
    isHorizontal: false,
    isStacked: false,
    noLabel: "nolabel",
    customColor: ["#C8102E", "#827375", "#A62D43", "#D47A86"]
  };
  const avgScoreCalcLength = lpSummaryData?.averageScore?.percentage.filter(
    (x: any) => x != 0
  ).length;
  const avgScoreChartData = {
    series: [
      {
        data: lpSummaryData && lpSummaryData?.averageScore?.percentage
      }
    ],
    columnWidth: "25",
    categories: lpSummaryData && lpSummaryData?.averageScore?.name,
    categoryColors: graphColors.gradeGraph,
    xAxisTitle: "Countries",
    yAxisTitle: "Percentage",
    leftTitle: selectedCountry ? "Average Score" : "Average Score - GCC",
    rightTitle: `Avg.Score: ${
      lpSummaryData?.averageScore?.percentage?.length > 0 && avgScoreCalcLength > 0
        ? (
            (lpSummaryData &&
              lpSummaryData?.averageScore?.percentage.reduce((a: any, b: any) => a + b, 0)) /
            avgScoreCalcLength
          )?.toFixed(2)
        : 0
    }%`,
    height: 300,
    yAxisTitleStyle: styles.fsGraphYAxisStyle,
    labelSuffix: "%",
    ymax: fetchYMax(lpSummaryData && lpSummaryData.averageScore?.percentage, 25)
  };

  const series = lpSummaryData && lpSummaryData?.actionPlansData?.series;
  const labels = lpSummaryData && lpSummaryData?.actionPlansData?.labels;
  const totalReports = lpSummaryData && lpSummaryData?.actionPlansData?.totalReports;
  const inProcessReport = lpSummaryData && lpSummaryData?.actionPlansData?.inProcessReport;

  const criticalPointsReportGraphData = {
    series: [
      {
        data: lpSummaryData && lpSummaryData?.criticalPointsReport?.averageDeductedPoints
      }
    ],
    categories: lpSummaryData && lpSummaryData?.criticalPointsReport?.labels,
    categoryColors: graphColors.gradeGraph,
    leftTitle: translate("criticalPoints"),
    labelSuffix: "",
    height: 300,
    ymax: fetchYMax(
      lpSummaryData && lpSummaryData?.criticalPointsReport?.averageDeductedPoints,
      20
    ),
    xAxisTitle: "KPI's",
    yAxisTitle: "Deductions"
  };

  return error ? (
    <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
  ) : loading ? (
    <Loader type="component" />
  ) : (
    lpSummaryData && (
      <>
        <LpReportSummaryTemplate
          translate={translate}
          user={user}
          gradeBarChartData={gradeBarChartData}
          PerformanceBrandStandardData={PerformanceBrandStandardData}
          MonthlyPerformanceBrandStandardData={MonthlyPerformanceBrandStandardData}
          momBarChartData={avgScoreChartData}
          criticalPointsReportGraph={criticalPointsReportGraphData}
          criticalCategoriesTable={lpSummaryData && lpSummaryData.criticalCategories}
          lpKPIData={lpSummaryData && lpSummaryData.lpKPIData}
          opportunityData={lpSummaryData && lpSummaryData.opportunityData}
          storeRankingTableHeaderData={lpStoreRankingTableHeaderData(translate)}
          series={series}
          labels={labels}
          totalReports={totalReports}
          inProcessReport={inProcessReport}
          actionPlanGraphHeight={300}
          showTopAndLastFive={true}
          kpiHeaderTitle={translate("By Country")}
          criticalPointHeaderTitle={translate("By Country")}
          storeRankingTableHeaderTitle={"Restaurant Ranking - GCC"}
          loading={loading}
          storeRakingTableLoading={storeRakingTableLoading}
          storeRakingTableError={storeRakingTableError}
          storeRankingTableData={lpSummaryData?.storeRankingTableData}
          pageNo={pageNumber}
          pageNoHandler={handlePageNo}
          handleCountryPage={handleCountryPage}
          handleAreaManagerPage={handleAreaManagerPage}
          handleLpAuditorPage={handleLpAuditorPage}
          areaManagerTotalAuditData={areaManagerTotalAuditData}
          countryTotalAuditData={countryTotalAuditData}
          lpAuditorTotalAuditData={lpAuditorTotalAuditData}
          lpAuditorPageNo={lpAuditorPageNo}
          areaManagerPageNo={areaManagerPageNo}
          countryPageNo={countryPageNo}
          totalAuditDetailsTablesLoading={totalAuditDetailsTablesLoading}
          auditsConducted={lpSummaryData?.auditsConducted}
          transfomedCountryWiseTable={transfomedCountryWiseTable}
          transformedAuditCountTable={transformedAuditCountTable}
          transformedFailedRestaurant={transformedFailedRestaurant}
          transfomedMonthQuarterTable={transfomedMonthQuarterTable}
        />
      </>
    )
  );
}

const styles = StyleSheet.create({
  fsGraphYAxisStyle: {
    alignSelf: "center",
    transform: [{ rotate: "270deg" }, { translateY: -275 }, { translateX: -150 }]
  }
});
