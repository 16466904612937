import React from "react";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Divider } from "@timhortons/common/src/components/atoms/divider";
import FlatList from "@timhortons/common/src/components/atoms/list";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { CountryWiseData } from "@timhortons/common/src/modules/lp/components/dashboard/reports/summary/template/lpReportSummaryTemplate";

interface IProps {
  loading: boolean;
  translate: any;
  headerTitle: string;
  transfomedCountryWiseTable: CountryWiseData[];
  handlePagination?: Function;
  containerStyle?: StyleProp<ViewStyle>;
}

export interface IHeaderData {
  id: string;
  name: string;
  cellStyle?: StyleProp<ViewStyle>;
  clickable?: boolean;
}

export default function BrandStandardCountryTableUi(props: IProps) {
  const {
    translate,
    transfomedCountryWiseTable,
    headerTitle = translate("By Stores"),
    loading = false,
    handlePagination,
    containerStyle
  } = props;
  const countryKeys =
    transfomedCountryWiseTable?.length > 0
      ? Object.keys(transfomedCountryWiseTable[0]).filter(
          (key) => key !== "id" && key !== "categoryName" && key !== "Total"
        )
      : [];

  const headerData: IHeaderData[] = [
    { id: "1", name: "S.No" },
    { id: "2", name: translate("Brand Standards") },
    ...countryKeys.map((country, index) => ({
      id: (index + 3).toString(),
      name: translate(country)
    }))
  ];

  const getCellUI = (value: number, header: boolean) => {
    return (
      <Cell title={value === null ? "-" : value + "%"} cellStyle={header && styles.headerColor} />
    );
  };
  const getTableRowUI = (item: any, index: any, header: boolean): JSX.Element => {
    return (
      <Row rowStyle={styles.flexStyle}>
        <Cell
          title={index + 1}
          cellStyle={[styles.contentCellsHeader, header && styles.headerColor]}
        />
        <Cell
          title={item.categoryName}
          cellStyle={[
            styles.contentCell,
            header && styles.headerColor,
            { justifyContent: "flex-start", paddingLeft: 18 }
          ]}
        />
        {countryKeys.map((country) => getCellUI(item[country], header))}
      </Row>
    );
  };

  const getHeaderUI = (header: IHeaderData[]) => {
    return header
      .filter((i) => i !== undefined)
      .map((item: IHeaderData, index: number) => (
        <React.Fragment key={index}>
          {/* {index === 5 && getDividerUI()} */}
          <HeaderCell
            title={item && item.name}
            cellStyle={[
              index === 0 && styles.contentCellsHeader,
              index === 1 && { paddingLeft: 18 }
            ]}
            wrapperStyles={{
              justifyContent: index === 1 ? "flex-start" : "center",
              flex: 1
            }}
            titleStyle={styles.centerAlign}
          />
        </React.Fragment>
      ));
  };

  return (
    <View style={[styles.flexStyle, containerStyle]}>
      <Table>
        <Row header>{getHeaderUI(headerData)}</Row>
        {loading ? (
          <Loader type="component" />
        ) : transfomedCountryWiseTable && transfomedCountryWiseTable.length > 0 ? (
          <>
            <FlatList
              extractor={(item) => item.id.toString()}
              listData={transfomedCountryWiseTable}
              seperator={() => <Divider dividerStyle={styles.solidDivider} />}
              listRenderer={(item: any, index) => getTableRowUI(item, index, false)}
            />
          </>
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
        )}
      </Table>
    </View>
  );
}

const styles = StyleSheet.create({
  dashedDivider: {
    borderWidth: 1,
    borderRadius: 0.1,
    borderStyle: "dashed",
    borderColor: colorPallete.grey7
  },
  solidDivider: {
    borderColor: colorPallete.white1
  },
  contentCell: {
    justifyContent: "center"
  },
  contentCellsHeader: {
    justifyContent: "center",
    minWidth: 100,
    flex: 0,
    paddingRight: 18,
    ...I18nService.select({
      rtl: { paddingEnd: 18 },
      ltr: { paddingStart: 18 }
    })
  },
  upperheaderColor: {
    backgroundColor: colorPallete.variant16
  },
  centerAlign: {
    textAlign: "center"
  },
  flexStyle: {
    flex: 1
  },
  headerColor: {
    backgroundColor: colorPallete.grey9
  },
  headerTextStyle: {
    padding: 24
  },
  textStyleChip: {
    borderRadius: 8,
    paddingVertical: 3,
    paddingHorizontal: 16,
    alignItems: "center"
  }
});
