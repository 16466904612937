import React from "react";
import { StyleSheet, View } from "react-native";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import LpActionplanGraph from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/lpActionPlanGraph";
import OverAllBrandStandardBarGraph from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/overallBrandStandardBarGraph";
import OverAllBrandStandardStackedBarGraph from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/brandStandardStackedBarGraph";
import BrandStandardTableUi from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/brandStandardTable";
import {
  LpIndividualCriticalCategoriesModel,
  LpKPIDataModel,
  LpReportsSummaryOpportunityDataModel,
  LpStoreRankingDataModel
} from "@timhortons/common/src/models/lp/reports";
import { IBarChartOptions } from "@timhortons/web/src/components/molecules/barChart";
import { IStackedBarChartOptions } from "@timhortons/web/src/components/molecules/stackedBarChart";
import { Roles } from "@timhortons/common/src/constants/roles";
import { ILpReportsLoading } from "@timhortons/common/src/redux/lp/reducers/reportsReducer";
import AuditsConducted from "@timhortons/common/src/modules/lp/components/dashboard/reports/organisms/auditsConductedTable";
import BrandStandardCountryTableUi from "../../organisms/brandStandardCountryTable";
import FailedRestaurantTable from "../../organisms/FailedRestaurantTable";
import AuditCountTables from "../../organisms/auditCountTable";
import ComparisonTable from "../../organisms/comparisonTable";
import { MonthlyCategoryDataProp } from "@timhortons/common/src/modules/lp/components/dashboard/reports/summary/admin";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { graphColors } from "@timhortons/common/src/utils/graphColorHelper";

export interface CountryWiseData {
  id: number;
  categoryName: string;
  [country: string]: number | string;
}

export interface AuditCountTableProps {
  id: number;
  auditorName: string;
  total: number;
  [key: string]: number | string | null;
}

interface FailedRestaurantProp {
  id: number;
  name: string;
  country: string;
  storeCode: number | string;
  auditedBy: string;
  storeManager: string;
}

export interface FailedReportProp {
  [key: string]: FailedRestaurantProp[];
}

interface IProps {
  translate: any;
  user: IUserDetails;
  gradeBarChartData: IBarChartOptions;
  PerformanceBrandStandardData: IStackedBarChartOptions;
  MonthlyPerformanceBrandStandardData: IStackedBarChartOptions;
  momBarChartData: IBarChartOptions;
  criticalPointsReportGraph: IBarChartOptions;
  criticalCategoriesTable: LpIndividualCriticalCategoriesModel[];
  lpKPIData: LpKPIDataModel[];
  opportunityData: LpReportsSummaryOpportunityDataModel[];
  storeRankingTableHeaderData: any;
  series: number[];
  labels: string[];
  totalReports: number;
  inProcessReport: number;
  actionPlanGraphHeight: number;
  showTopAndLastFive: boolean;
  kpiHeaderTitle: string;
  criticalPointHeaderTitle: string;
  storeRankingTableHeaderTitle: string;
  loading: boolean;
  storeRakingTableLoading: boolean;
  storeRakingTableError: string;
  storeRankingTableData: LpStoreRankingDataModel;
  pageNoHandler: Function;
  handleCountryPage: Function;
  handleAreaManagerPage: Function;
  handleLpAuditorPage: Function;
  pageNo: number;
  areaManagerTotalAuditData: any;
  countryTotalAuditData: any;
  lpAuditorTotalAuditData: any;
  lpAuditorPageNo: number;
  areaManagerPageNo: number;
  countryPageNo: number;
  totalAuditDetailsTablesLoading: ILpReportsLoading;
  auditsConducted: any;
  transfomedCountryWiseTable: CountryWiseData[];
  transformedAuditCountTable: AuditCountTableProps[];
  transformedFailedRestaurant: FailedReportProp[];
  transfomedMonthQuarterTable: MonthlyCategoryDataProp[];
}

export default function LpReportSummaryTemplate(props: IProps) {
  const {
    translate,
    user,
    gradeBarChartData,
    auditsConducted,
    MonthlyPerformanceBrandStandardData,
    series,
    labels,
    totalReports,
    inProcessReport,
    PerformanceBrandStandardData,
    criticalCategoriesTable,
    criticalPointHeaderTitle,
    loading,
    transfomedCountryWiseTable,
    transformedAuditCountTable,
    transformedFailedRestaurant,
    transfomedMonthQuarterTable
  } = props;
  let allowedArr: Array<Roles> = [Roles.HeadLP];

  return (
    <View style={styles.container}>
      <View style={styles.secondElementConatiner}>
        <View style={styles.actionplanGraphContainer}>
          <LpActionplanGraph
            translate={translate}
            series={series}
            labels={labels}
            totalReports={totalReports}
            inProcessReport={inProcessReport}
            leftHeader={translate("Audit Performance")}
            wantFooter={false}
            containerStyle={styles.containerStyle}
            bodyContainerStyle={styles.bodyContainerStyle}
            isDividerStyle={true}
            customColor={graphColors.auditPerformance}
            textContainerStyle={styles.textContainerStyle}
            donutContainerStyle={styles.donutContainerStyle}
          />
        </View>
        <View style={styles.bsTableContainer}>
          <AuditsConducted
            translate={translate}
            auditsConducted={auditsConducted}
            headerTitle={criticalPointHeaderTitle}
            loading={loading}
          />
        </View>
      </View>
      <View style={styles.commonBorder}>
        <View style={styles.gradeGraphContainer}>
          <OverAllBrandStandardBarGraph
            barChartData={gradeBarChartData}
            containerStyle={styles.whiteBorder}
          />
        </View>
        <View style={styles.bsTableContainer}>
          <BrandStandardTableUi
            translate={translate}
            criticalCategoriesData={criticalCategoriesTable}
            headerTitle={criticalPointHeaderTitle}
            loading={loading}
          />
        </View>
      </View>
      <View style={styles.commonBorder}>
        <View style={styles.gradeGraphContainer}>
          <OverAllBrandStandardStackedBarGraph
            barChartData={PerformanceBrandStandardData}
            containerStyle={styles.whiteBorder}
          />
        </View>
        <View style={styles.bsTableContainer}>
          <BrandStandardCountryTableUi
            translate={translate}
            transfomedCountryWiseTable={transfomedCountryWiseTable}
            headerTitle={criticalPointHeaderTitle}
            loading={loading}
          />
        </View>
      </View>

      <View style={styles.commonBorder}>
        <View style={styles.gradeGraphContainer}>
          <OverAllBrandStandardStackedBarGraph
            barChartData={MonthlyPerformanceBrandStandardData}
            containerStyle={styles.whiteBorder}
          />
        </View>
        <View style={styles.bsTableContainer}>
          <ComparisonTable
            translate={translate}
            transfomedMonthQuarterTable={transfomedMonthQuarterTable}
            headerTitle={criticalPointHeaderTitle}
            loading={loading}
          />
        </View>
      </View>

      <View style={styles.bsTableContainer}>
        <FailedRestaurantTable
          translate={translate}
          loading={loading}
          headerTitle={criticalPointHeaderTitle}
          transformedFailedRestaurant={transformedFailedRestaurant}
        />
      </View>
      <View style={styles.bsTableContainer}>
        <AuditCountTables
          translate={translate}
          loading={loading}
          headerTitle={criticalPointHeaderTitle}
          transformedAuditCountTable={transformedAuditCountTable}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // backgroundColor: colorPallete.white3,
    // borderRadius: 8,
    // padding: 30
  },
  firstElementConatiner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  secondElementConatiner: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  gradeGraphContainer: {
    marginBottom: 30
  },
  commonBorder: {
    marginBottom: 30,
    borderWidth: 1,
    borderColor: colorPallete.grey7,
    borderRadius: 4
  },
  momGraphContainer: {
    flex: 1,
    marginLeft: 24
  },
  actionplanGraphContainer: {
    flex: 1,
    marginRight: 24,
    borderWidth: 1,
    borderColor: colorPallete.grey7,
    borderRadius: 4
  },
  fsGraphConatiner: {
    flex: 1,
    marginLeft: 24
  },
  bsTableContainer: {
    flex: 1
  },
  opportunityTableContainer: {
    marginBottom: 24
  },
  storeRankingTableContainer: {},
  containerStyle: {
    paddingVertical: 35,
    borderColor: "white",
    paddingBottom: 0,
    height: "100%",
    paddingTop: 0
  },
  bodyContainerStyle: {
    paddingBottom: 2,
    paddingTop: 88
  },
  textContainerStyle: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 88,
    justifyContent: "space-evenly"
  },
  donutContainerStyle: {
    display: "flex",
    flexDirection: "column"
  },
  whiteBorder: {
    borderColor: colorPallete.whiite7
  }
});
