import React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import Card from "@timhortons/common/src/components/atoms/card";
import { IBarChartOptions } from "@timhortons/web/src/components/molecules/barChart";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
const BarChartGroup = React.lazy(() => import("@timhortons/web/src/components/molecules/barChart"));
import MobileBarChartGroup from "@timhortons/tablet/src/components/molecules/barChart";
interface IProps {
  barChartData: IBarChartOptions;
  containerStyle?: StyleProp<ViewStyle>;
  barWidthSpace?: string;
}

export default function OverAllBrandStandardBarGraph(props: IProps) {
  const { barChartData, containerStyle, barWidthSpace } = props;

  return (
    <View style={[styles.container, containerStyle]}>
      <Card.Body bodyStyle={styles.bodyContainer}>
        {PlatformUtils.isWeb() ? (
          <View>
            <React.Suspense fallback={null}>
              <BarChartGroup barChartData={barChartData} />
            </React.Suspense>
          </View>
        ) : (
          <MobileBarChartGroup barWidthSpace={barWidthSpace} barChartData={barChartData} />
        )}
      </Card.Body>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colorPallete.grey7,
    borderRadius: 4,
    flex: 1
  },
  bodyContainer: {
    flex: 1,
    width: "100%"
  }
});
