import React, { useState } from "react";
import { View, TouchableOpacity, StyleSheet, ViewStyle, StyleProp } from "react-native";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import { FontWeight, TextSize, Text } from "@timhortons/common/src/components/atoms/text";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FailedReportProp } from "@timhortons/common/src/modules/lp/components/dashboard/reports/summary/template/lpReportSummaryTemplate";

interface IProps {
  loading: boolean;
  translate: any;
  headerTitle: string;
  transformedFailedRestaurant: FailedReportProp[];
}
export interface IHeaderData {
  id: string;
  name: string;
  cellStyle?: StyleProp<ViewStyle>;
  clickable?: boolean;
}

const FailedRestaurantTable = (props: IProps) => {
  const {
    translate,
    headerTitle = translate("By Stores"),
    loading = false,
    transformedFailedRestaurant
  } = props;

  const [visibleRows, setVisibleRows] = useState(
    transformedFailedRestaurant.map((category: any) => ({
      key: Object.keys(category)[0],
      value: 10
    }))
  );

  const handleShowMore = (categoryKey: string) => {
    setVisibleRows((prev: any) =>
      prev.map((item: any) =>
        item.key === categoryKey
          ? {
              ...item,
              value: transformedFailedRestaurant.find(
                (category: any) => Object.keys(category)[0] === categoryKey
              )[categoryKey].length
            }
          : item
      )
    );
  };
  const headerData: IHeaderData[] = [
    { id: "1", name: translate("Brand Standard") },
    { id: "2", name: translate("S.No") },
    { id: "3", name: translate("Restaurant Name") },
    { id: "4", name: translate("Country") },
    { id: "5", name: translate("Store Code") },
    { id: "6", name: translate("Audited By") },
    { id: "7", name: translate("Store Manager") }
  ];

  const getHeaderUI = (header: IHeaderData[]) => {
    return header
      .filter((i) => i !== undefined)
      .map((item: IHeaderData, index: number) => (
        <React.Fragment key={index}>
          <HeaderCell
            title={item && item.name}
            cellStyle={index === 0 && styles.contentCellsHeader}
            wrapperStyles={{
              justifyContent: "center",
              flex: 1
            }}
            titleStyle={styles.centerAlign}
          />
          {index === 0 && <View style={styles.dividerContainer} />}
        </React.Fragment>
      ));
  };

  const getTableRowUI = (item: any, index: any, header: boolean): JSX.Element => {
    return (
      <Row rowStyle={styles.flexStyle}>
        <Cell
          title={translate(index + 1)}
          cellStyle={[styles.contentCell, header && styles.headerColor]}
        />
        <Cell
          title={translate(item.name)}
          cellStyle={[styles.contentCell, header && styles.headerColor]}
        />
        <Cell
          title={translate(item.country)}
          cellStyle={[styles.contentCell, header && styles.headerColor]}
        />
        <Cell
          title={translate(item.storeCode)}
          cellStyle={[styles.contentCell, header && styles.headerColor]}
        />
        <Cell
          title={translate(item.auditedBy)}
          cellStyle={[styles.contentCell, header && styles.headerColor]}
        />
        <Cell
          title={translate(item.storeManager)}
          cellStyle={[styles.contentCell, header && styles.headerColor]}
        />
      </Row>
    );
  };

  return (
    <View testID="container" style={[styles.flexStyle, styles.container]}>
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.SemiBold}
        testId="opportunity table"
        textStyle={styles.heading}
      >
        {translate("Brand Standards - Failed Restaurant Details")}
      </Text>
      <Table>
        <Row header>{getHeaderUI(headerData)}</Row>

        {loading ? (
          <Loader type="component" />
        ) : transformedFailedRestaurant.length > 0 ? (
          transformedFailedRestaurant.map((categoryObj: any, categoryIndex: any) => {
            const categoryKey = Object.keys(categoryObj)[0];
            const records = categoryObj[categoryKey];
            const visibleCount =
              visibleRows.find((item: any) => item.key === categoryKey)?.value || 10;

            return (
              <View key={categoryKey} testID="categoryKey" style={styles.brandStandaradContainer}>
                <Row>
                  <Cell
                    title={translate(categoryKey)}
                    cellStyle={[
                      styles.categoryContainer,
                      styles.headerColor,
                      { marginBottom: visibleCount > 10 ? 10 : 0, marginTop: 0 }
                    ]}
                  />
                  <View style={{ flex: 6 }}>
                    {records.slice(0, visibleCount).map((record: any, index: any) => (
                      <View
                        style={[styles.row, index < records.length - 1 && styles.straightLine]}
                        key={record.id}
                      >
                        {getTableRowUI(record, index, false)}
                      </View>
                    ))}
                  </View>
                </Row>

                {visibleCount < records.length && (
                  <Row>
                    <Cell
                      cellStyle={[styles.categoryContainer, styles.headerColor, { marginTop: 0 }]}
                    />
                    <View style={[styles.showMoreContainer]} testID="showMoreContainer">
                      <Text
                        textSize={TextSize.ExtraRegular}
                        fontWeight={FontWeight.Regular}
                        testId="opportunitmy table"
                        textStyle={styles.heading}
                      >
                        {`Total : ${records.length} Restaurants`}
                      </Text>
                      <TouchableOpacity onPress={() => handleShowMore(categoryKey)}>
                        <Text
                          textSize={TextSize.ExtraRegular}
                          fontWeight={FontWeight.SemiBold}
                          testId="opportunity table"
                          textStyle={styles.showMoreHeading}
                        >
                          {translate("Show more ▼")}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </Row>
                )}

                {categoryIndex < transformedFailedRestaurant.length - 1 && (
                  <View style={styles.dashedLine} />
                )}
              </View>
            );
          })
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
        )}
      </Table>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colorPallete.grey7,
    paddingBottom: 10
  },
  table: {
    width: "100%"
  },
  heading: {
    padding: 24
  },
  contentCellsHeader: {
    justifyContent: "center",
    paddingLeft: 18,
    paddingRight: 18
  },
  contentCell: {
    justifyContent: "center",
    textAlign: "center"
  },
  brandStandaradContainer: {
    marginTop: 10
  },
  centerAlign: {
    textAlign: "center"
  },
  categoryContainer: {
    justifyContent: "center",
    flex: 1,
    backgroundColor: "#f9f9f9",
    padding: 10,
    textAlign: "center",
    marginLeft: 10,
    marginRight: 20
  },
  row: {
    flexDirection: "row",
    paddingVertical: 10
  },
  straightLine: {
    borderBottomWidth: 1,
    borderBottomColor: "#ddd"
  },
  dashedLine: {
    borderBottomWidth: 1,
    borderBottomColor: "#827375",
    borderStyle: "dashed",
    marginVertical: 10
  },
  flexStyle: {
    flex: 1
  },
  headerColor: {
    backgroundColor: colorPallete.grey9
  },
  showMoreContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: "#FAFAFA",
    flex: 6,
    margin: 10,
    marginLeft: 0,
    marginRight: 0
  },
  showMoreHeading: {
    color: "#C8102E",
    padding: 24
  },
  dividerContainer: {
    width: 10,
    backgroundColor: colorPallete.whiite7
  }
});

export default FailedRestaurantTable;
