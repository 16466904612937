import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { LpReportsActions } from "@timhortons/common/src/redux/lp/actions/reportsAction";
import { ReportsRepository } from "@timhortons/common/src/repositories/lp/reports";
import {
  LpAcknowledgementModel,
  LpPostAcknowledgementModel,
  LpReportsDataModal,
  LpReportTableResponseModel,
  LpStoreAccessSummaryDataModel,
  LpIndividualAccessSummaryDataModel,
  LpReportsSummaryOpportunityDataModel,
  AllAreaManagersListDataModel,
  LPReportBuilderResponseModel,
  KPIDataModel,
  LpStoreRankingDataModel,
  LpAuditChecklistDataModel
} from "@timhortons/common/src/models/lp/reports";
import { Logger } from "@timhortons/common/src/services/logger";
import { convert } from "@timhortons/common/src/utils/dateFormat";
import {
  AllStoresListDataModel,
  ChannelsListDataModel,
  CountryDataListModel
} from "@timhortons/common/src/models/profile";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { ComparsionLevel } from "@timhortons/common/src/components/organisms/builder";

function* submitLpAuditWorkerSaga(value: IFluxStandardAction<{ auditId: number }>) {
  const { payload } = value;
  try {
    let response: { auditId: number; reportId: null } = yield call(
      ReportsRepository.submitLpAudit,
      payload
    );
    yield put(LpReportsActions.submitLpAuditSuccess(response));
  } catch (error) {
    Logger.error("lp-reports", error, "submitLpAuditWorkerSaga");

    yield put(LpReportsActions.submitLpAuditError(error));
  }
}
function* prepareLpReportWorkerSaga(value: IFluxStandardAction<{ auditId: number }>) {
  const { payload } = value;
  try {
    let reponse: { reportId: number; auditId: number } = yield call(
      ReportsRepository.prepareLpReport,
      payload
    );
    yield put(LpReportsActions.submitLpAuditSuccess(reponse));
  } catch (error) {
    Logger.error("lp-reports", error, "prepareLpReportWorkerSaga");

    yield put(LpReportsActions.submitLpAuditError(error));
  }
}
function* getLpReportsDataWorkerSaga(
  value: IFluxStandardAction<{
    auditId: number;
    reportId: number;
  }>
) {
  try {
    const { payload } = value;
    const response: LpReportsDataModal = yield call(ReportsRepository.fetchLpReportsData, payload);
    yield put(LpReportsActions.getLpReportsDataSuccess(response));
  } catch (error) {
    Logger.error("lp-reports", error, "getLpReportsDataWorkerSaga");

    yield put(LpReportsActions.getLpReportsDataError(error));
  }
}

function* getLpAcknowledgementWorkerSaga(value: IFluxStandardAction<{ storeCode: string }>) {
  try {
    const { payload } = value;
    const response: LpAcknowledgementModel = yield call(
      ReportsRepository.fetchAcknowledgementData,
      payload
    );
    yield put(LpReportsActions.getLpAcknowledgementDataSuccess(response));
  } catch (error) {
    Logger.error("lp-reports", error, "getLpAcknowledgementWorkerSaga");

    yield put(LpReportsActions.getLpAcknowledgementDataError(error));
  }
}
function* postLpAcknowledgementWorkerSaga(value: IFluxStandardAction<LpPostAcknowledgementModel>) {
  try {
    const { payload } = value;
    const response: {} = yield call(ReportsRepository.postAcknowledgementData, payload);
    yield put(LpReportsActions.postLpAcknowledgementSuccess(response));
  } catch (error) {
    Logger.error("lp-reports", error, "postLpAcknowledgementWorkerSaga");

    yield put(LpReportsActions.postLpAcknowledgementError(error));
  }
}

function* getLpReportTableWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;

    let bodyObject: any = {
      page: payload.pageNumber,
      size: payload.pageSize,
      sortByAuditDate: payload.sortOrder.toUpperCase(),
      searchByKeyword: payload.search === "" ? null : payload.search
    };

    if (payload.fromDate !== null) {
      bodyObject = {
        ...bodyObject,
        filterByStartDate: convert(payload.fromDate)
      };
    }
    if (payload.toDate !== null) {
      bodyObject = {
        ...bodyObject,
        filterByEndDate: convert(payload.toDate)
      };
    }

    const response: LpReportTableResponseModel = yield call(
      ReportsRepository.fetchReportTableData,
      {
        userId: payload.userId,
        storeId: payload.storeId,
        isIndividualLogin: payload.isIndividualLogin,
        bodyObject: bodyObject
      }
    );
    yield put(LpReportsActions.getLpReportTableDataSuccess(response));
  } catch (error) {
    Logger.error("lp-reports", error, "getLpReportTableaWorkerSaga");

    yield put(LpReportsActions.getLpReportTableDataError(error));
  }
}

function* geLptStoreAccessSummaryDataWorkerSaga(
  value: IFluxStandardAction<{ storeId: number; startDate: string; endDate: string }>
) {
  try {
    const { payload } = value;
    const response: LpStoreAccessSummaryDataModel = yield call(
      ReportsRepository.fetchLpReportsSummaryStoreAccess,
      payload
    );
    yield put(LpReportsActions.getLpStoreAccessSummaryDataSuccess(response));
  } catch (error) {
    Logger.error("lp-reports", error, "getLpStoreAccessSummaryDataWorkerSaga");

    yield put(LpReportsActions.getLpStoreAccessSummaryDataError(error));
  }
}

function* getLpIndividualSummaryDataWorkerSaga(
  value: IFluxStandardAction<{
    startDate: string;
    endDate: string;
    countryCode: number;
    pageNo: number;
    pageSize: number;
  }>
) {
  try {
    const { payload } = value;
    const response: LpIndividualAccessSummaryDataModel = yield call(
      ReportsRepository.fetchLpIndividualReportsSummary,
      payload
    );

    yield put(LpReportsActions.getLpIndividualSummaryDataSuccess(response));
    if (response) {
      const res: LpStoreRankingDataModel = yield call(
        ReportsRepository.fetchLpIndividualSummarySRTableData,
        payload
      );

      yield put(LpReportsActions.getLpIndividualSummaryStoreRankingDataSuccess(res));
    }
  } catch (error) {
    yield put(LpReportsActions.getLpIndividualSummaryDataError(error));
    Logger.error("lp-reports", error, "getLpIndividualSummaryDataWorkerSaga");
  }
}

function* getLpAuditChecklistDataWorkerSaga(
  value: IFluxStandardAction<{
    startDate: string;
    endDate: string;
    countryCode: number;
    pageNo: number;
    pageSize: number;
  }>
) {
  try {
    const { payload } = value;
    const response: LpAuditChecklistDataModel = yield call(
      ReportsRepository.fetchLpAuditChecklistReport,
      payload
    );

    yield put(LpReportsActions.getLpAuditChecklistDataSuccess(response));
  } catch (error) {
    yield put(LpReportsActions.getLpAuditChecklistDataError(error));
  }
}
function* getLpIndividualSummaryStoreRankingTableWorkerSaga(
  value: IFluxStandardAction<{
    startDate: string;
    endDate: string;
    countryCode: number;
    pageNo: number;
    pageSize: number;
  }>
) {
  try {
    const { payload } = value;
    const response: LpStoreRankingDataModel = yield call(
      ReportsRepository.fetchLpIndividualSummarySRTableData,
      payload
    );

    yield put(LpReportsActions.getLpIndividualSummaryStoreRankingDataSuccess(response));
  } catch (error) {
    yield put(LpReportsActions.getLpIndividualSummaryStoreRankingDataError(error));
    Logger.error("lp-reports", error, "getLpIndividualSummaryStoreRankingTableWorkerSaga");
  }
}

function* getCountryTotalAuditDetailsWorkerSaga(
  value: IFluxStandardAction<{
    // reportType:string;
    startDate: string;
    endDate: string;
    countryCode: number;
    pageNo: number;
    pageSize: number;
  }>
) {
  try {
    const { payload } = value;
    const response: any[] = yield call(ReportsRepository.fetchTotalAuditDetails, {
      ...payload,
      reportType: "Country"
    });

    yield put(LpReportsActions.getCountryTotalAuditsSuccess(response));
  } catch (error) {
    yield put(LpReportsActions.getCountryTotalAuditsError(error));
    Logger.error("lp-reports", error, "getCountryTotalAuditDetailsWorkerSaga");
  }
}

function* getLpAuditorTotalAuditDetailsWorkerSaga(
  value: IFluxStandardAction<{
    // reportType:string;
    startDate: string;
    endDate: string;
    countryCode: number;
    pageNo: number;
    pageSize: number;
  }>
) {
  try {
    const { payload } = value;
    const response: any[] = yield call(ReportsRepository.fetchTotalAuditDetails, {
      ...payload,
      reportType: "Lp Auditor"
    });

    yield put(LpReportsActions.getLpAuditorTotalAuditsSuccess(response));
  } catch (error) {
    yield put(LpReportsActions.getLpAuditorTotalAuditsError(error));
    Logger.error("lp-reports", error, "getLpAuditorTotalAuditDetailsWorkerSaga");
  }
}

function* getAreaManagerTotalAuditDetailsWorkerSaga(
  value: IFluxStandardAction<{
    // reportType:string;""
    startDate: string;
    endDate: string;
    countryCode: number;
    pageNo: number;
    pageSize: number;
  }>
) {
  try {
    const { payload } = value;
    const response: any[] = yield call(ReportsRepository.fetchTotalAuditDetails, {
      ...payload,
      reportType: "Area Manager"
    });
    yield put(LpReportsActions.getAreaManagerTotalAuditsSuccess(response));
  } catch (error) {
    yield put(LpReportsActions.getAreaManagerTotalAuditsError(error));
    Logger.error("lp-reports", error, "getAreaManagerTotalAuditDetailsWorkerSaga");
  }
}

function* getLpStoreAccessOpportunityDataWorkerSaga(
  value: IFluxStandardAction<{ storeId: number; startDate: string; endDate: string }>
) {
  try {
    const { payload } = value;
    const response: LpReportsSummaryOpportunityDataModel[] = yield call(
      ReportsRepository.fetchLpReportsOpportunityStoreAccess,
      payload
    );
    yield put(LpReportsActions.getLpStoreAccessOpportunityDataSuccess(response));
  } catch (error) {
    Logger.error("lp-reports", error, "getLpStoreAccessOpportunityDataWorkerSaga");

    yield put(LpReportsActions.getLpStoreAccessOpportunityDataError(error));
  }
}

function* generateReportBuilderWorkerSaga(
  value: IFluxStandardAction<{
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
    selectedDateRange?: string;
    quaterLabel?: string;
  }>
) {
  const { payload } = value;
  try {
    const response: LPReportBuilderResponseModel = yield call(
      ReportsRepository.generateReportBuilder,
      payload
    );
    yield put(LpReportsActions.generateReportBuilderSuccess(response));
  } catch (error) {
    Logger.error("LP/reports", error, "generateReportBuilderWorkerSaga");

    yield put(
      LpReportsActions.generateReportBuilderError({
        error,
        reportType: payload?.object?.levelOfReport
      })
    );
  }
}

function* generateGradingReportBuilderWorkerSaga(
  value: IFluxStandardAction<{
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }>
) {
  const { payload } = value;
  try {
    const response: LPReportBuilderResponseModel = yield call(
      ReportsRepository.generateGradingReportBuilder,
      payload
    );
    yield put(LpReportsActions.generateGradingReportBuilderSuccess(response));
  } catch (error) {
    Logger.error("LP/reports", error, "generateGradingReportBuilderWorkerSaga");

    yield put(LpReportsActions.generateGradingReportBuilderError(error));
  }
}

function* generateAreaOfImprovementReportBuilderWorkerSaga(
  value: IFluxStandardAction<{
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }>
) {
  const { payload } = value;
  try {
    const response: any[] = yield call(
      ReportsRepository.generateAreaOfImprovementReportBuilder,
      payload
    );
    yield put(LpReportsActions.generateAreaOfImprovementReportBuilderSuccess(response));
  } catch (error) {
    Logger.error("LP/reports", error, "generateAreaOfImprovementReportBuilderWorkerSaga");

    yield put(LpReportsActions.generateAreaOfImprovementReportBuilderError(error));
  }
}

function* brandStandardTableDataWorkerSaga(
  value: IFluxStandardAction<{
    object: any;
    reportType: string;
    user: IUserDetails;
    countryCodes?: any;
    allSelected?: boolean;
    pageNum: number;
    updateTableName: ComparsionLevel;
  }>
) {
  const { payload } = value;

  try {
    const response: any[] = yield call(ReportsRepository.brandStandardTableData, payload);
    yield put(LpReportsActions.brandStandardTableDataSuccess(response));
  } catch (error) {
    Logger.error("LP/reports", error, "brandStandardTableDataWorkerSaga");

    yield put(LpReportsActions.brandStandardTableDataError(error));
  }
}

export default function* lpReportsWatcherSaga() {
  yield takeLatest(LP.SUBMIT_LP_AUDIT.LOADING, submitLpAuditWorkerSaga);
  yield takeLatest(LP.PREPARE_LP_REPORT.LOADING, prepareLpReportWorkerSaga);
  yield takeLatest(LP.LP_ACKNOWLEDGEMENT_DATA.LOADING, getLpAcknowledgementWorkerSaga);
  yield takeLatest(LP.POST_LP_ACKNOWLEDGEMENT.LOADING, postLpAcknowledgementWorkerSaga);
  yield takeLatest(
    LP.LP_GET_STORE_ACCESS_SUMMARY_DATA.LOADING,
    geLptStoreAccessSummaryDataWorkerSaga
  );
  yield takeLatest(LP.LP_REPORTS_DATA.LOADING, getLpReportsDataWorkerSaga);
  yield takeEvery(LP.GET_LP_REPORT_TABLE_DATA.LOADING, getLpReportTableWorkerSaga);
  yield takeLatest(LP.GET_LP_INDIVIDUAL_SUMMARY_DATA.LOADING, getLpIndividualSummaryDataWorkerSaga);
  yield takeLatest(
    LP.GET_LP_STORE_ACCESS_OPPORTUNITY_DATA.LOADING,
    getLpStoreAccessOpportunityDataWorkerSaga
  );
  yield takeEvery(LP.ReportBuilder.GENERATE_REPORT.LOADING, generateReportBuilderWorkerSaga);
  yield takeLatest(
    LP.ReportBuilder.GENERATE_GRADING_REPORT.LOADING,
    generateGradingReportBuilderWorkerSaga
  );
  yield takeLatest(
    LP.GET_LP_INDIVIDUAL_SUMMARY_STORE_RANKING_DATA.LOADING,
    getLpIndividualSummaryStoreRankingTableWorkerSaga
  );
  yield takeLatest(LP.GET_COUNTRY_TOTAL_AUDITS.LOADING, getCountryTotalAuditDetailsWorkerSaga);
  yield takeLatest(
    LP.ReportBuilder.BRAND_STANDARD_TABLE_DATA.LOADING,
    brandStandardTableDataWorkerSaga
  );
  yield takeLatest(
    LP.ReportBuilder.GENERATE_AREA_OF_IMPROVEMENT_REPORT.LOADING,
    generateAreaOfImprovementReportBuilderWorkerSaga
  );
  yield takeLatest(LP.GET_LP_AUDITOR_TOTAL_AUDITS.LOADING, getLpAuditorTotalAuditDetailsWorkerSaga);
  yield takeLatest(
    LP.GET_AREAMANAGER_TOTAL_AUDITS.LOADING,
    getAreaManagerTotalAuditDetailsWorkerSaga
  );
  yield takeLatest(LP.GET_LP_AUDIT_CHECKLIST_DATA.LOADING, getLpAuditChecklistDataWorkerSaga);
}
