import React from "react";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { ScrollView, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import { Divider, Orientations } from "@timhortons/common/src/components/atoms/divider";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { FontWeight, TextSize, Text, Label } from "@timhortons/common/src/components/atoms/text";
import { I18nService } from "@timhortons/common/src/services/internalization/I18nextService";
import { LpAuditChecklistDataModel } from "@timhortons/common/src/models/lp/reports";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/lp/components/dashboard/organisms/jumbotron";
import Loader from "@timhortons/common/src/components/molecules/loader";
import CommonPagination from "@timhortons/common/src/modules/rev/organisms/commonPagination";
import { IUserDetails } from "modules/iam/redux/reducer/reducer";

interface IProps {
  loading?: boolean;
  translate: any;
  headerTitle?: string;
  user: IUserDetails;
  lpAuditChecklistData: LpAuditChecklistDataModel;
  error: string;
  paginationHandler: Function;
  pageno: number;
}

export interface IHeaderData {
  id: string;
  name: string;
  cellStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  clickable?: boolean;
  colSpan?: number;
  background?: string;
}
export const statusStyle = (status: string) => {
  switch (status) {
    case "PASS":
      return {
        color: colorPallete.green3,
        backgroundColor: "#2196531A"
      };
    case "FAIL":
      return {
        color: colorPallete.red1,
        backgroundColor: "#EB32231A"
      };
    default:
      return {
        color: colorPallete.green1
      };
  }
};

export default function AuditChecklistTableUi(props: IProps) {
  const {
    translate,
    user,
    headerTitle = translate("By Stores"),
    loading = false,
    lpAuditChecklistData,
    error,
    paginationHandler,
    pageno
  } = props;

  const additionalHeaderData: IHeaderData[] = [
    {
      id: "1",
      name: translate("Particulars"),
      colSpan: 7,
      background: "#366990D",
      textStyle: styles.textHeader
    },
    ...Array.from({ length: 17 }, (_, i) => ({
      id: (i + 2).toString(),
      name: (i + 1).toString(),
      background: "#366990D",
      textStyle: styles.textHeader
    })),
    {
      id: "19",
      name: translate("Report"),
      colSpan: 4,
      background: "#366990D",
      textStyle: styles.textHeader
    }
  ];

  function generateDynamicHeaders(data: any) {
    return (
      data?.auditChecklistData[0]?.checklists.map((item: any) => ({
        id: (item.categoryId + 8).toString(),
        name: item.categoryName
      })) || []
    );
  }
  const dynamicHeaders = generateDynamicHeaders(lpAuditChecklistData);

  // Static headers
  const staticHeaders: IHeaderData[] = [
    { id: "1", name: translate("No"), textStyle: styles.textHeader },
    { id: "2", name: translate("Date"), textStyle: styles.textHeader },
    { id: "3", name: translate("Code"), textStyle: styles.textHeader },
    { id: "4", name: translate("Store"), textStyle: styles.textHeader },
    { id: "5", name: translate("Region"), textStyle: styles.textHeader },
    { id: "6", name: translate("SM Name"), textStyle: styles.textHeader },
    { id: "7", name: translate("SM Emp. ID"), textStyle: styles.textHeader }
  ];

  // Final headerData combining static and dynamic headers
  const headerData: IHeaderData[] = [
    ...staticHeaders,
    ...dynamicHeaders,
    { id: "25", name: translate("Total Earning Points") },
    { id: "26", name: translate("Total Earning Points %") },
    { id: "27", name: translate("Status") },
    { id: "28", name: translate("Audited By") }
  ];

  const getDividerUI = (): JSX.Element => {
    return <Divider orientation={Orientations.Vertical} dividerStyle={styles.dashedDivider} />;
  };

  const getTableRowUI = (item: any, index: number): JSX.Element => {
    const checklistMap = item.checklists.reduce((acc: any, curr: any) => {
      acc[curr.categoryName] = curr.count;
      return acc;
    }, {});

    return (
      <Row key={index} rowStyle={styles.flexStyle}>
        {/* Static columns */}
        <Cell
          title={index + 1}
          cellStyle={[styles.contentCellsHeader, { justifyContent: "center" }]}
        />
        <Cell title={item.date || "-"} cellStyle={styles.contentCell} />
        <Cell title={item.storeCode || "-"} cellStyle={styles.contentCell} />
        <Cell title={item.storeName || "-"} cellStyle={styles.contentCell} />
        <Cell title={item.country || "-"} cellStyle={styles.contentCell} />
        <Cell title={item.storeManagerName || "-"} cellStyle={styles.contentCell} />
        <Cell title={item.storeManagerId || "-"} cellStyle={styles.contentCell} />
        {getDividerUI()}
        {/* Dynamic columns based on headerData */}
        {headerData.slice(7, 24).map((header) => (
          <>
            <Cell
              key={header.id}
              title={checklistMap[header.name] ?? "-"}
              cellStyle={styles.contentCell}
            />
            {getDividerUI()}
          </>
        ))}
        <Cell title={item.totalEarningPoints || "-"} cellStyle={styles.contentCell} />
        <Cell
          title={
            isNaN(Number(item.totalEarningPercentage))
              ? "-"
              : `${Number(item.totalEarningPercentage).toFixed(2)}%`
          }
          cellStyle={styles.contentCell}
        />

        {/* Status Cell */}
        <Cell cellStyle={styles.contentCell}>
          <Label
            textSize={TextSize.ExtraMedium}
            fontWeight={FontWeight.SemiBold}
            testId="label status"
            style={[styles.textStyleChip, statusStyle(item.status)]}
          >
            <Text textSize={TextSize.Regular} fontWeight={FontWeight.SemiBold} testId="id">
              {item.status}
            </Text>
          </Label>
        </Cell>
        <Cell title={item.auditedBy || "-"} cellStyle={styles.contentCell} />
      </Row>
    );
  };

  const getHeaderUI = (header: IHeaderData[], headerType: string, dividerIndex: number) => {
    return header
      .filter((i) => i !== undefined)
      .map((item: IHeaderData, index: number) => (
        <React.Fragment key={index}>
          {headerType === "additionalHeader"
            ? (index === header.length - 1 || (index !== 0 && index <= dividerIndex)) &&
              getDividerUI()
            : index > 6 && index <= dividerIndex && getDividerUI()}

          <HeaderCell
            title={item && item.name}
            cellStyle={[
              styles.contentCellsHeader,
              {
                flex: item.colSpan || 1,
                backgroundColor: item.background || "#FAFAFA",
                minWidth: 100
              }
            ]}
            wrapperStyles={{
              justifyContent: "center",
              flex: 1
            }}
            titleStyle={[styles.centerAlign, item.textStyle]}
          />
        </React.Fragment>
      ));
  };

  return loading ? (
    <Loader type="component" />
  ) : lpAuditChecklistData && lpAuditChecklistData.auditChecklistData.length > 0 ? (
    <View style={[styles.flexStyle, styles.container]}>
      <Text
        textSize={TextSize.ExtraRegular}
        fontWeight={FontWeight.SemiBold}
        testId="opportunity table"
        textStyle={styles.headerTextStyle}
      >
        {translate("Audit checklist Overview")}
      </Text>
      <ScrollView horizontal={true}>
        <Table>
          <Row header>{getHeaderUI(additionalHeaderData, "additionalHeader", 17)}</Row>
          <Row header>{getHeaderUI(headerData, "header", 24)}</Row>
          {lpAuditChecklistData.auditChecklistData.map((item, index) =>
            getTableRowUI(item, index + pageno * 10)
          )}
        </Table>
      </ScrollView>

      {/* Pagination */}
      <CommonPagination
        handlePagination={(page: number) => {
          paginationHandler(page);
        }}
        pageNum={pageno + 1}
        totalRecords={lpAuditChecklistData && lpAuditChecklistData.totalElements}
      />
    </View>
  ) : (
    <Jumbotron type={ErrorType.NoDataFound} title={translate("noDataAvailable")} />
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colorPallete.grey7
  },
  dashedDivider: {
    borderWidth: 1,
    borderRadius: 0.1,
    borderStyle: "dashed",
    borderColor: colorPallete.grey7
  },
  solidDivider: {
    borderColor: colorPallete.white1
  },
  contentCell: {
    justifyContent: "center",
    // ...I18nService.select({
    //   rtl: { paddingEnd: 18 },
    //   ltr: { paddingStart: 18 }
    // }),
    textAlign: "center"
  },
  contentCellsHeader: {
    justifyContent: "flex-start",
    // ...I18nService.select({
    //   rtl: { paddingEnd: 18 },
    //   ltr: { paddingStart: 18 }
    // }),
    textAlign: "center"
  },
  textStyleChip: {
    borderRadius: 8,
    paddingVertical: 3,
    paddingHorizontal: 16,
    alignItems: "center",
    backgroundColor: colorPallete.grey8
  },
  upperheaderColor: {
    backgroundColor: colorPallete.variant16
  },
  centerAlign: {
    textAlign: "center"
  },
  flexStyle: {
    flex: 1
  },
  headerColor: {
    backgroundColor: colorPallete.grey9
  },
  headerTextStyle: {
    padding: 24
  },
  textHeader: {
    fontWeight: "bold"
  }
});
