import { StyleSheet, View } from "react-native";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { Roles } from "@timhortons/common/src/constants/roles";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { Text, FontWeight, TextSize } from "@timhortons/common/src/components/atoms/text";
import { LpReportsSelectors } from "@timhortons/common/src/redux/lp/selectors/reportsSelector";
import { IState } from "@timhortons/common/src/store/interfaces";
import { LpReportsActions } from "@timhortons/common/src/redux/lp/actions/reportsAction";
import { convert, dateFormat, dateToTZ } from "@timhortons/common/src/utils/dateFormat";
import { LpAuditChecklistDataModel } from "@timhortons/common/src/models/lp/reports";
import {
  ILpReportsError,
  ILpReportsLoading
} from "@timhortons/common/src/redux/lp/reducers/reportsReducer";
import DownloadButton from "@timhortons/common/src/components/organisms/builder/overallReport/downloadButton";
import { getCsvDownloader } from "@timhortons/web/src/utils/docsDownloader";
import { LPEndPoints } from "@timhortons/common/src/constants/endPoints";
import { LPKeys } from "@timhortons/common/src/constants/apiKeys";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import AuditChecklistTableUi from "./admin";
import { CountryDataListModel } from "models/profile";

interface IProps {
  user: IUserDetails;
  translate?: any;
  selectedMonth?: { fromDate: Date; toDate: Date; monthly: any; quarterly: any };
  selectedCountry?: any;
  selectedMonthQuatee?: {};
  getLpAuditChecklistData?: Function;
  lpAuditChecklistData?: LpAuditChecklistDataModel;
  loading?: ILpReportsLoading;
  error?: ILpReportsError;
  gccCountryData: CountryDataListModel;
}
function AuditChecklist(props: IProps) {
  const {
    translate,
    user,
    selectedMonth,
    selectedCountry,
    getLpAuditChecklistData,
    lpAuditChecklistData,
    loading,
    error,
    gccCountryData
  } = props;
  const [pageNo, setPageNo] = useState(0);

  const [isPageChange, setIsPageChange] = useState(false);

  const [isDownloadBtnDisabled, setIsDownloadBtnDisabled] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState<boolean>(false);

  const getMonthRangeLabel = (
    monthRange: { fromDate: Date; toDate: Date },
    hasYear: boolean
  ): string => {
    let tempLabel: string = "";
    if (monthRange && monthRange.fromDate && monthRange.toDate) {
      // let startLabel: string = monthsGregorian.find(
      //   (el) => el.id === monthRange.fromDate?.getMonth()
      // ).label;
      // let endLabel: string = monthsGregorian.find(
      //   (el) => el.id === monthRange.toDate?.getMonth()
      // ).label;
      // if (hasYear) {
      //   startLabel = `${startLabel} ${selectedMonth.fromDate?.getFullYear() || ""}`;
      //   endLabel = `${endLabel} ${selectedMonth.toDate?.getFullYear() || ""}`;
      // }
      // tempLabel = ` ${startLabel} - ${endLabel}`;
      tempLabel = `${dateFormat(monthRange.fromDate)} - ${dateFormat(monthRange.toDate)}`;
    }
    return tempLabel;
  };

  const downloadHandler = async () => {
    setIsDownloadBtnDisabled(true);
    try {
      const { storeCode, roles } = user;
      const { fromDate, toDate } = selectedMonth;

      const params = {
        startDate: fromDate ? dateToTZ(fromDate) : null,
        endDate: toDate ? dateToTZ(toDate) : null,
        countryCode: roles?.includes(Roles.StoreAccess) ? null : selectedCountry
      };

      const endPoint = LPEndPoints.reports.lpAuditCsvDownload();

      await getCsvDownloader({ endPoint, xApiKey: LPKeys.LP_REPORT_BUILDER, params });
    } catch (_err) {
      setDownloadFailed(true);
    }
    setIsDownloadBtnDisabled(false);
    return "Downloaded";
  };
  useEffect(() => {
    console.log("gccCountryData", gccCountryData);
    console.log("selected", selectedCountry);
    console.log("slected month", selectedMonth);
  });

  useEffect(() => {
    console.log("selectedMonth i audittttt", selectedMonth);
    console.log("convert(selectedMonth.fromDate)", convert(selectedMonth.fromDate));
    !user.roles.includes(Roles.StoreAccess) &&
      getLpAuditChecklistData({
        startDate: convert(selectedMonth.fromDate),
        endDate: convert(selectedMonth.toDate),
        countryCode: selectedCountry,
        pageNo: 0,
        pageSize: 10,
        quarter:
          selectedMonth.quarterly && selectedMonth.quarterly.month.label.replace(/Quarter\s?/, "Q")
      });
    setPageNo(0);
    setIsPageChange(false);
  }, [
    convert(selectedMonth.fromDate),
    convert(selectedMonth.toDate),
    selectedCountry,
    selectedMonth
  ]);

  useEffect(() => {
    if (!isPageChange) return;

    getLpAuditChecklistData({
      startDate: convert(selectedMonth.fromDate),
      endDate: convert(selectedMonth.toDate),
      countryCode: selectedCountry,
      pageNo: pageNo,
      pageSize: 10
    });

    setIsPageChange(false);
  }, [pageNo]);

  const paginationHandler = (val: number) => {
    setIsPageChange(true);
    setPageNo(val - 1);
  };

  const selectedCountryText = (gccData: CountryDataListModel, selected: any) => {
    if (!selected) return " ";
    const selectedArray = selected.split(",").map(Number);
    if (selectedArray.length === gccData.content.length) {
      return "- GCC";
    } else {
      const label = gccData.content
        .filter((item: any) => selectedArray.includes(item.countryCode))
        .map((item: any) => item.value)
        .join(", ");
      return `- ${label}`;
    }
  };

  const renderComponent = useMemo(() => {
    let userRole = user?.roles[0];
    let result: ReactElement = <View></View>;
    switch (userRole) {
      // case Roles.Admin:
      case Roles.SuperAdmin:
      case Roles.TrainingAndDevManager:
      case Roles.TrainingAndDevLead:
      case Roles.HrAndAdminDirector:
      case Roles.ProductionAndDevExecutive:
      case Roles.CEO:
      case Roles.COO:
      case Roles.HeadLP:
      case Roles.AuditorLP:
      case Roles.CountryManager:
      case Roles.ClusterHeadofOps:
      case Roles.SeniorOpsManager:
      case Roles.OperationManager:
      case Roles.AreaManager:
      case Roles.AreaTrainer:
      case Roles.StoreAccess:
        result = (
          <AuditChecklistTableUi
            translate={translate}
            user={user}
            lpAuditChecklistData={lpAuditChecklistData}
            loading={loading.lpAuditChecklistData}
            error={error.lpAuditChecklistData}
            paginationHandler={paginationHandler}
            pageno={pageNo}
          />
        );
        break;
      default:
        result = null;
        break;
    }
    return result;
  }, [user, loading, error, pageNo]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <View style={styles.headerTitle}>
            <View style={{ flexDirection: "row" }}>
              <Text
                textSize={TextSize.Large}
                fontWeight={FontWeight.SemiBold}
                testId="summary-header"
                textStyle={styles.summaryHeader}
              >
                {translate("lp")}
              </Text>
              <Text
                textSize={TextSize.Large}
                fontWeight={FontWeight.SemiBold}
                testId="summary-header"
                textStyle={styles.summaryReportHeader}
              >
                {(selectedMonth?.monthly?.month?.label &&
                  `- ${selectedMonth?.monthly?.month?.label}`) ||
                  (selectedMonth?.quarterly?.month?.label &&
                    `- ${selectedMonth?.quarterly?.month?.label}`) ||
                  ""}
                {translate(" Report ")} {selectedCountryText(gccCountryData, selectedCountry)}
              </Text>
            </View>
            <Text
              textSize={TextSize.ExtraSmall}
              fontWeight={FontWeight.Regular}
              testId="summary-header"
              textStyle={styles.lastUpdated}
            >
              {translate("lastUpdated")} {getMonthRangeLabel(selectedMonth, true)}
            </Text>
          </View>
          <DownloadButton
            translate={translate}
            title={"Download as CSV"}
            isDisabled={isDownloadBtnDisabled}
            handleBack={downloadHandler}
          />
        </View>
        {downloadFailed && (
          <AlertModal
            errorMsg={translate("downloadFailed")}
            setModalVisible={function (): void {
              setDownloadFailed(false);
            }}
          />
        )}
        {renderComponent}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colorPallete.white3,
    borderRadius: 8,
    padding: 30
  },
  headerContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24
  },
  headerTitle: {
    flex: 0.5,
    flexDirection: "column"
  },
  summaryHeader: { color: colorPallete.black3 },
  summaryReportHeader: { color: colorPallete.black3, marginStart: 8 },
  lastUpdated: { color: colorPallete.grey1 }
});

const mapStateToProps = (state: IState) => {
  return {
    selectedMonth: LpReportsSelectors.getLpSelectedMonthFilter(state),
    selectedCountry: LpReportsSelectors.getLpSelectedCountryFilter(state),
    lpAuditChecklistData: LpReportsSelectors.getLpAuditChecklistAccessData(state),
    loading: LpReportsSelectors.lpReportsLoading(state),
    error: LpReportsSelectors.lpReportsError(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...LpReportsActions }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditChecklist);
