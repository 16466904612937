export const colorPallete = {
  black1: "#000000",
  black2: "#13090A",
  black3: "#211012",
  black4: "#30171A",
  black5: "#333333",
  grey1: "#594547",
  grey2: "#827375",
  grey3: "#ACA2A3",
  grey4: "#C0B9BA",
  grey5: "#D5D0D1",
  grey6: "#DDD9DA",
  grey7: "#E5E2E3",
  grey8: "#E1DEDE",
  grey9: "#F2F0F0",
  grey10: "#F3F2F2",
  grey11: "#EEECEC",
  grey12: "#EFECEC",
  grey13: "#4F4F4F",
  grey14: "#F7F7F9",
  grey15: "#808D9B",
  grey16: "#ACA2A3",
  grey17: "#E7E8E8",
  grey18: "#E5E5E5",
  grey19: "#E5E7E6",
  grey20: "#E5E8EC",
  grey21: "#EEEEEE",
  grey22: "#707070",
  grey23: "#ECEDF0",
  grey24: "#ECEDF0",
  grey25: "#DDD9DA",
  grey26: "#ECEDF0",
  grey27: "#DDD9DA",
  grey28: "#F8F8F8",
  white1: "#F6F5F5",
  white2: "#FAFAFA",
  white3: "#FFFFFF",
  white4: "#F3F3F3",
  white5: "#F5F5F5",
  white6: "#FDF7F8",
  whiite7: "#FCFCFC",
  white8: "#D9D9D9",
  white9: "#FCFCFF",
  white10: "#F4F5F7",
  white11: "#F2F3F2",
  white12:"#EBDDC1",
  white13:"#F9F4DE",
  red1: "#C8102E",
  red2: "#AE0E2A",
  red3: "#BA5826",
  red4: "#ff3333",
  red5: "#CF142E",
  red6: "#EB3223",
  red7: "#FF2222",
  red8: "#9B0020",
  red9: "#C81431",
  red10: "#AD0024",
  red11: "#F6EEEF",
  red12: "#EB5757",
  red13: "#CE0E2D",
  red14: "#BD2C34",
  red15: "#F2994A",
  red16: "#CE0E2D",
  red17: "#9E0015",
  yellow1: "#E68825",
  yellow2: "#F7CE47",
  yellow3: "#EF9B39",
  yellow4: "#FFE76A",
  yellow5: "#E68835",
  yellow6: "#F39200",
  yellow7: "#F2C94C",
  peach1: "#D2C3C5",
  green1: "#74AA50",
  green2: "#4EAC5B",
  green3: "#A0CD63",
  green4: "#27AE60",
  green5: "#308E3D",
  green6: "#446F3F",
  green7: "#4D2C0A",
  green8: "#80A85B",
  green9: "#219653",
  green10: "#E2F2EA",
  green11: "#603D19",
  green12: "#F4FAF6",
  green13: "#019942",
  green14: "#7DA559",
  blue1: "#6CACE4",
  blue2: "#2492D1",
  blue3: "#19324A",
  blue4: "#001B36",
  blue5: "#222B45",
  gold1: "#EAC895",
  brown1: "#3F2021",
  brown2: "#73250E",
  brown3: "#4D2C0A",
  brown4: "#735343",
  brown5: "#BF8751",
  brown6: "#C5AB9F",
  donutCream: "#DDCBA4",
  variant1: "rgba(174, 14, 42, 0.05)",
  variant2: "rgba(235, 87, 87, 0.05)",
  variant3: "rgba(173, 0, 36, 0.05)",
  variant4: "rgba(173, 0, 36, 0.1)",
  variant5: "rgba(33, 150, 83, 0.05)",
  variant6: "rgba(19, 9, 10, 0.38)",
  variant7: "rgba(0, 0, 0, 0.04)",
  variant8: "rgba(33, 150, 83, 0.1)",
  variant9: "rgba(235, 50, 35, 0.1)",
  variant10: "rgba(173, 0, 36, 0.5)",
  variant11: "rgba(160, 205, 99, 0.1)",
  variant12: "rgba(247, 206, 71, 0.1)",
  variant13: "rgba(239, 155, 57, 0.1)",
  variant14: "rgba(246, 238, 239, 0.5)",
  variant15: "rgba(89, 69, 71, 0.05)",
  variant16: "rgba(51, 102, 153, 0.05)",
  variant17: "rgba(250, 250, 250, 0.85)",
  variant18: "rgba(130, 115, 117, 0.05);",
  variant19: "rgba(255, 51, 51, 0.45)",
  variant20: "rgba(33, 150, 83, 0.45)",
  variant21: "rgba(0, 0, 0, 0.12)",
  variant22: "rgba(0, 0, 0, 0.06)",
  variant23: "rgba(247, 247, 247, 0.85)",
  variant24: "rgba(48, 23, 26, 0.05)",
  variant25: "rgba(173, 0, 36, 0.1)",
  variant26: "rgba(48, 23, 26, 0.1)",
  variant27: "rgba(27, 28, 32, 0.1)",
  variant28: "rgba(200, 16, 46, 0.05)",
  variant29: "rgba(173, 0, 36, 0.3)",
  variant32: "rgba(200, 16, 46, 0.07)",
  variant33: "rgba(63, 32, 33, 0.07)",
  variant34: "rgba(200, 16, 46, 0.4)"
};
